<template>
    <div>
        <FormTitle
            title="Demande de contact assurance automobile"
            iconName="mdi-car"
        />

        <FormCategory title="Le conducteur habituel">
            <FormContactSection
                type-demande="AUTO"
                @toggleSecondConducteur="toggleSecondConducteur"
            >
                <template #bonus-malus>
                    <FormAutoBonusMalus />
                </template>
            </FormContactSection>
        </FormCategory>

        <FormCategory v-if="secondConducteur" title="Le second conducteur">
            <FormContactSection type-demande="AUTO" :recursive="true">
                <template #bonus-malus>
                    <FormAutoBonusMalus :recursive="true" />
                </template>
            </FormContactSection>
        </FormCategory>

        <FormCategory title="Véhicule à assurer">
            <FormAutoSection />
        </FormCategory>

        <FormCategory title="Justificatifs à fournir (8 Mo max. par fichier)">
            <FormAutoDocumentsSection :secondConducteur="secondConducteur" />
        </FormCategory>
    </div>
</template>

<script>
import FormTitle from '@/components/FormTitle';
import FormCategory from '@/components/FormCategory';
import FormContactSection from '@/components/FormContactSection';
import FormAutoSection from '@/components/FormAutoSection';
import FormAutoDocumentsSection from '@/components/FormAutoDocumentsSection';
import FormAutoBonusMalus from '@/components/FormAutoBonusMalus.vue';

export default {
    name: 'FormAuto',

    components: {
        FormTitle,
        FormContactSection,
        FormAutoSection,
        FormAutoDocumentsSection,
        FormCategory,
        FormAutoBonusMalus,
    },

    data() {
        return {
            secondConducteur: false,
        };
    },

    methods: {
        toggleSecondConducteur(payload) {
            this.secondConducteur = payload;
        },
    },
};
</script>
