<template lang="pug">
FormLoading(v-if="loading", :loading="loading")
v-container(v-else, fluid)
    v-speed-dial.actions(
        v-model="actions",
        fixed,
        fab,
        top,
        right,
        direction="bottom",
        transition="slide-y-transition"
    )
        template(v-slot:activator="")
            v-tooltip(left)
                template(v-slot:activator="{ on }")
                    v-btn(
                        v-model="actions",
                        :loading="loading",
                        color="secondary",
                        fab,
                        v-on="on"
                    )
                        v-icon(v-if="actions")
                            | mdi-close
                        v-icon(v-else)
                            | mdi-cog
                span Actions
        v-tooltip(v-for="(statut, i) in statutsAutorises", :key="i", left)
            template(v-slot:activator="{ on }")
                v-btn(
                    fab,
                    small,
                    color="secondary",
                    @click="modifierStatut(statut.code)",
                    v-on="on"
                )
                    v-icon {{ statut.icon }}
            span {{ statut.libelle }}
        v-tooltip(v-if="demande.demandeStatutActuel == 'CONTRAT'", left)
            template(v-slot:activator="{ on }")
                v-btn(
                    fab,
                    dark,
                    small,
                    color="green",
                    @click="modifier",
                    v-on="on"
                )
                    v-icon mdi-content-save
            span Sauvegarder

    v-row
        v-col
            Bloc(title="Contrat", subtitle="Informations contractuelles")
                template(v-slot:content)
                    v-container(fluid)
                        v-form(
                            ref="contratForm",
                            v-model="contratFormValid",
                            lazy-validation
                        )
                            v-row.align-center
                                v-col(cols="12", sm="6", md="6")
                                    v-text-field(
                                        label="N° de contrat",
                                        v-model="numeroContrat",
                                        :disabled="demande.demandeStatutActuel !== 'CONTRAT'",
                                        :rules="[(v) => !!v || 'Le numéro de contrat est obligatoire']"
                                    )
                                v-col(cols="12", sm="3", md="3")
                                    v-text-field(
                                        label="Point de vente",
                                        readonly,
                                        :value="demande.point_vente_nom"
                                    )
                                v-col(cols="12", sm="3", md="3")
                                    v-text-field(
                                        label="Vendeur",
                                        readonly,
                                        :value="demande.vendeur_prenom + ' ' + demande.vendeur_nom"
                                    )

    v-row
        v-col
            Bloc(
                title="Conducteur habituel",
                subtitle="Coordonnées du conducteur habituel"
            )
                template(v-slot:content)
                    v-container(fluid)
                        v-form
                            v-row.align-center.justify-center
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.civilites",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Civilité",
                                        readonly,
                                        :value="demande.conducteur_habituel_civilite"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Prénom",
                                        readonly,
                                        :value="demande.conducteur_habituel_prenom"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Nom",
                                        readonly,
                                        :value="demande.conducteur_habituel_nom"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.situations_familiale",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Situation de famille",
                                        readonly,
                                        :value="demande.conducteur_habituel_famille"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.professions",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Profession",
                                        readonly,
                                        :value="demande.conducteur_habituel_profession"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Adresse",
                                        readonly,
                                        :value="demande.conducteur_habituel_adresse"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Complément d'adresse",
                                        readonly,
                                        :value="demande.conducteur_habituel_complement"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Code postal",
                                        readonly,
                                        :value="demande.conducteur_habituel_cp"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Ville",
                                        readonly,
                                        :value="demande.conducteur_habituel_ville"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Adresse e-mail",
                                        prepend-icon="mdi-at",
                                        readonly,
                                        :value="demande.conducteur_habituel_email"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Téléphone",
                                        prepend-icon="mdi-phone",
                                        readonly,
                                        :value="demande.conducteur_habituel_tel"
                                    )

                            v-row
                                v-col.mt-10(cols="12", sm="12", md="6")
                                    v-slider(
                                        label="Bonus",
                                        step="0.01",
                                        min="0.50",
                                        max="1.25",
                                        thumb-label="always",
                                        ticks="",
                                        readonly,
                                        :value="demande.conducteur_habituel_bonus"
                                    )

                            v-row.align-center.justify-center
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Déjà client",
                                        readonly,
                                        :value="demande.conducteur_habituel_client ? 'Oui' : 'Non'"
                                    )

    v-row
        v-col
            Bloc(
                title="Second conducteur",
                subtitle="Coordonnées du second conducteur",
                v-if="demande.conducteurSecondaire"
            )
                template(v-slot:content)
                    v-container(fluid)
                        v-form
                            v-row.align-center.justify-center
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.civilites",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Civilité",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_civilite"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Prénom",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_prenom"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Nom",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_nom"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.situations_familiale",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Situation de famille",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_famille"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.professions",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Profession",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_profession"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Adresse",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_adresse"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Complément d'adresse",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_complement"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Code postal",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_cp"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Ville",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_ville"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Adresse e-mail",
                                        prepend-icon="mdi-at",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_email"
                                    )
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Téléphone",
                                        prepend-icon="mdi-phone",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_tel"
                                    )

                            v-row
                                v-col.mt-10(cols="12", sm="12", md="6")
                                    v-slider(
                                        label="Bonus",
                                        step="0.01",
                                        min="0.50",
                                        max="1.25",
                                        thumb-label="always",
                                        ticks="",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_bonus"
                                    )

                            v-row.align-center.justify-center
                                v-col(cols="12", sm="6", md="3")
                                    v-text-field(
                                        label="Déjà client",
                                        readonly,
                                        :value="demande.conducteurSecondaire.conducteur_secondaire_client ? 'Oui' : 'Non'"
                                    )

    v-row
        v-col
            Bloc(
                title="Véhicule à assurer",
                subtitle="Informations du véhicule à assurer"
            )
                template(v-slot:content)
                    v-container(fluid)
                        v-form
                            v-row.align-center.justify-center
                                v-col(cols="12", sm="6", md="3")
                                    v-select(
                                        :items="referentielDemandeAuto.types_vehicule",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Type de véhicule",
                                        readonly,
                                        :value="demande.vehicule_type"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="6")
                                    v-select(
                                        :items="referentielDemandeAuto.modes_financement",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Mode de financement",
                                        readonly,
                                        :value="demande.vehicule_modefinancement"
                                    )
                                v-col(cols="12", sm="6", md="6")
                                    v-select(
                                        :items="referentielDemandeAuto.garanties",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Garanties souhaitées",
                                        readonly,
                                        :value="demande.garantie_code"
                                    )

                            v-row.mb-10.justify-center
                                v-btn(
                                    color="primary",
                                    elevation="2",
                                    x-large="",
                                    outlined="",
                                    @click="openSinistresAutoDialog"
                                )
                                    v-icon(left="") mdi-car-cog
                                    | Sinistralité des conducteurs sur les 36 derniers mois
                                DemandeAutoSinistresAutoDialog(
                                    :dialog="sinistresDialog",
                                    :demande="demande",
                                    :referentiel-demande="referentielDemandeAuto",
                                    @open-sinistres-auto-dialog="openSinistresAutoDialog()",
                                    @close-sinistres-auto-dialog="closeSinistresAutoDialog()"
                                )

    v-row
        v-col(cols="12", sm="6", md="6")
            Bloc(title="Suivi du dossier")
                template(v-slot:content)
                    v-container(fluid)
                        v-form(
                            ref="suiviForm",
                            v-model="suiviFormValid",
                            lazy-validation
                        )
                            v-row
                                v-col(cols="12", sm="6", md="6")
                                    v-select(
                                        :items="referentielDemande.statuts",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Statut",
                                        readonly,
                                        :value="demande.demandeStatutActuel"
                                    )
                                v-col(cols="12", sm="6", md="6")
                                    v-text-field(
                                        label="Date de changement du statut",
                                        readonly,
                                        :value="new Date(demandeDateStatut).toLocaleString('fr-FR')"
                                    )

                            v-row
                                v-col(cols="12", sm="6", md="6")
                                    v-select(
                                        :items="referentielDemande.types_paiement",
                                        item-text="libelle",
                                        item-value="code",
                                        label="Type de paiement",
                                        v-model="demandeTypePaiement",
                                        :disabled="demande.demandeStatutActuel !== 'CONTRAT'",
                                        :rules="[(v) => !!v || 'Le type de paiement est obligatoire']"
                                    )
                                v-col(cols="12", sm="6", md="6")
                                    v-text-field(
                                        label="Tarif",
                                        suffix="€",
                                        v-model="demandeTarif",
                                        type="number",
                                        min="0.01",
                                        step="0.01",
                                        :disabled="demande.demandeStatutActuel !== 'CONTRAT'",
                                        :rules="[(v) => !!v || 'Le tarif est obligatoire']"
                                    )

                            //- ALZRAD-68
                            //- v-row(v-if="demandeTypePaiement !== 'ANNUEL'")
                            //-   v-col(cols='12' sm='6' md='6')
                            //-     v-text-field(label='Montant du fractionné' suffix='€' v-model="demandeMontantFractionne" type="number" min="0.01" step="0.01" :disabled="demande.demandeStatutActuel !== 'CONTRAT'" :rules="[(v) => !!v || 'Le montant du fractionné est obligatoire']")

        v-col(cols="12", sm="6", md="6")
            Bloc(title="Justificatifs")
                template(v-slot:content)
                    v-container(fluid)
                        v-list
                            v-list-item-group(color="primary")
                                v-list-item(
                                    v-for="(justificatif, i) in demande.justificatifs",
                                    :key="i",
                                    @click="telecharger(justificatif)"
                                )
                                    v-list-item-icon
                                        v-icon mdi-paperclip
                                    v-list-item-title {{ justificatif }}

    SnackBar(v-bind:show="showSnackBar", v-bind:message="message")
</template>

<script>
import DemandeAutoService from "@/services/demande-auto.service";
import DemandeService from "@/services/demande.service";

import Bloc from "@/components/common/Bloc";
import SnackBar from "@/components/common/SnackBar";
import FormLoading from "@/components/FormLoading.vue";
import DemandeAutoSinistresAutoDialog from "@/components/demande/DemandeAutoSinistresAutoDialog";

export default {
    name: "DemandeAutoForm",
    components: {
        Bloc,
        SnackBar,
        FormLoading,
        DemandeAutoSinistresAutoDialog,
    },
    data: () => ({
        showSnackBar: false,
        message: {
            type: "error",
            message: "test",
        },
        loading: false,
        demande: {},
        referentielDemande: {},
        referentielDemandeAuto: {},
        actions: false,
        justificatifs: [],
        transitionsAutorisees: {
            DEMANDE_DEVIS: {
                icon: "mdi-new-box",
                statuts_autorises: [
                    "PROPOSITION_FAITE",
                    "SANS_SUITE",
                    "REFUS",
                    "CONTRAT",
                ],
            },
            PROPOSITION_FAITE: {
                icon: "mdi-account-check",
                statuts_autorises: [
                    "DEMANDE_DEVIS",
                    "SANS_SUITE",
                    "REFUS",
                    "CONTRAT",
                ],
            },
            SANS_SUITE: {
                icon: "mdi-timer-cancel",
                statuts_autorises: [
                    "DEMANDE_DEVIS",
                    "PROPOSITION_FAITE",
                    "REFUS",
                    "CONTRAT",
                ],
            },
            REFUS: { icon: "mdi-close-octagon", statuts_autorises: [] },
            CONTRAT: { icon: "mdi-file-document-edit", statuts_autorises: [] },
        },
        statutsAutorises: [],
        numeroContrat: null,
        demandeTypePaiement: null,
        demandeTarif: null,
        demandeMontantFractionne: null,
        demandeDateStatut: null,
        contratFormValid: true,
        suiviFormValid: true,
        sinistresDialog: false,
    }),
    async created() {
        await this.afficher();
        await this.afficherForm(this.demande.vendeur_code);
        await this.getReferentials();
    },
    methods: {
        async telecharger(justificatif) {
            await DemandeService.telecharger(justificatif)
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", justificatif);
                    document.body.appendChild(link);
                    link.click();
                    this.justificatif = response;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async getReferentials() {
            await DemandeService.getReferentials()
                .then((response) => {
                    this.referentielDemande = response.data;
                    this.setStatutsAutorises(response.data.statuts);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async afficherForm(vendeurCode) {
            await DemandeService.afficherForm(vendeurCode)
                .then((response) => {
                    this.referentielDemandeAuto = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async afficher() {
            try {
                this.showSnackBar = false;
                this.loading = true;
                const response = await DemandeAutoService.afficher(
                    this.$route.params.id
                );
                this.message = {
                    type: response.success ? "success" : "error",
                    message: response.message,
                };

                this.demande = response.data;

                this.numeroContrat = response.data.numero_contrat
                    ? response.data.numero_contrat
                    : null;
                this.demandeTypePaiement = response.data.demandeTypePaiement
                    ? response.data.demandeTypePaiement
                    : null;
                this.demandeTarif = response.data.demandeTarif
                    ? response.data.demandeTarif
                    : null;
                this.demandeMontantFractionne = response.data
                    .demandeMontantFractionne
                    ? response.data.demandeMontantFractionne
                    : null;
                this.demandeDateStatut =
                    response.data.demandeStatutActuelDate.dateStatut;
            } catch (error) {
                this.message = {
                    type: "error",
                    message: error.response.data.message,
                };
            } finally {
                this.loading = false;
                this.showSnackBar = true;
            }
        },
        async modifier() {
            try {
                this.showSnackBar = false;
                this.contratFormvalid = this.$refs.contratForm.validate();
                this.suiviFormvalid = this.$refs.suiviForm.validate();

                if (this.contratFormValid && this.suiviFormValid) {
                    this.loading = true;
                    const response = await DemandeService.modifier(
                        this.demande.demandeId,
                        {
                            numeroContrat: this.numeroContrat,
                            tarif: this.demandeTarif,
                            typePaiement: this.demandeTypePaiement,
                            montantFractionne: this.demandeMontantFractionne,
                        }
                    );
                    this.message = {
                        type: response.success ? "success" : "error",
                        message: response.message,
                    };

                    this.afficher();
                }
            } catch (error) {
                this.message = {
                    type: "error",
                    message: error.response.data.message,
                };
                console.error(error);
            } finally {
                this.loading = false;
                this.showSnackBar = true;
            }
        },
        setStatutsAutorises(statuts) {
            let statutsAutorises = [];
            for (const statut of statuts) {
                statut.icon = this.transitionsAutorisees[statut.code].icon;
                const transitionsAutorisees =
                    this.transitionsAutorisees[
                        this.demande.demandeStatutActuel
                    ];
                if (
                    transitionsAutorisees.statuts_autorises.includes(
                        statut.code
                    )
                ) {
                    statutsAutorises.push(statut);
                }
            }
            this.statutsAutorises = statutsAutorises;
        },
        async modifierStatut(statutCode) {
            try {
                this.showSnackBar = false;
                this.loading = true;
                const response = await DemandeService.modifierStatut(
                    this.demande.demandeId,
                    {
                        statut: statutCode,
                    }
                );
                this.message = {
                    type: response.success ? "success" : "error",
                    message: response.message,
                };

                await this.afficher();
                this.setStatutsAutorises(this.referentielDemande.statuts);
            } catch (error) {
                this.message = {
                    type: "error",
                    message: error.response.data.message,
                };
                console.error(error);
            } finally {
                this.loading = false;
                this.showSnackBar = true;
            }
        },

        openSinistresAutoDialog() {
            this.sinistresDialog = true;
        },

        closeSinistresAutoDialog() {
            this.sinistresDialog = false;
        },
    },
};
</script>
