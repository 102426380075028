<template lang="pug">
  v-app
    v-main(app)
      v-container(fluid fill-height)
        v-fade-transition(mode='out-in')
          router-view
</template>

<script>
export default {
  name: "NakedLayout",
};
</script>
