import NakedLayout from "@/components/layout/NakedLayout";
import Login from "@/views/authentification/Login";
import ResetPassword from "../views/reset-password/ResetPassword";
import store from "@/store";
import RequestResetPassword from "../views/reset-password/RequestResetPassword";
import FormView from "../views/FormView";

const PublicRoute = {
  path: "/",
  component: NakedLayout,
  children: [
    { path: "/login", name: "Authentification", component: Login },
    {
      path: "/logout",
      name: "Logout",
      component: {
        beforeRouteEnter(to, from, next) {
          store.dispatch("auth/logout");
          next("/login");
        },
      },
    },
    {
      path: "/reset/password",
      name: "RequestResetPassword",
      component: RequestResetPassword,
    },
    {
      path: "/reset/password/:token",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
        path: '/:qr_code_code',
        name: 'Form',
        component: FormView,
    },
  ],
};
export default PublicRoute;
