<template>
    <div class="text-center">
        <v-dialog :value="dialog" :width="width" @click:outside="closeDialog" @keydown.esc="closeDialog">
            <v-card elevation="0">
                <v-card-title class="text-h5 primary--text font-weight-bold">
                    {{ name }}

                    <v-spacer></v-spacer>

                    <v-btn icon @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <slot name="dialog-content"></slot>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'FormDialog',

    props: {
        width: {
            type: Number,
            default: 500,
        },
        name: {
            type: String,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        openDialog() {
            this.$emit('open-dialog');
        },
        closeDialog() {
            this.$emit('close-dialog');
        },
    },
};
</script>
