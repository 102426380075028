<template lang="pug">
div
  v-card
    v-card-title.primary.white--text {{title}}
    v-card-subtitle.primary.white--text(v-if="subtitle") {{subtitle}}

    v-card-text
      slot(name="content")
</template>

<script>
export default {
  name: "Bloc",

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>
