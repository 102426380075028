<template>
    <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
    name: 'FormLoading',

    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
