<template lang="pug">
BasicCrud(
  title="",
  title-modal="un utilisateur",
  item-name="user",
  :service="service"
  :headers='headers',
  :default-item='defaultItem',
  max-width-dialog="600px",
  :form-is-valid="formIsValid"
  :default-search="defaultSearch"
)
  template(v-slot:alert)
    v-alert.mx-3(v-if="alert.show" :type="alert.type" )  {{alert.text}}

  template(v-slot:search="{ search,list,dataRef }" )
    v-container(fluid)
      v-form
        v-row.align-center
          v-col(cols='12' sm='6' md='4')
            v-text-field(v-model="search['user.person.firstName[LIKE]']" label="Prénom" outlined hide-details clearable)
    
          v-col(cols='12' sm='6' md='4')
            v-text-field(v-model="search['user.person.lastName[LIKE]']" label="Nom" outlined hide-details clearable)
    
          v-col(cols='12' sm='6' md='4')
            v-text-field(v-model="search['user.email[LIKE]']" label="Email" outlined hide-details clearable)

        v-row.align-center
          v-col(cols='12' sm='8' md='8')
            v-select(v-model="search['user.roles[=]']" label="Role" :items="dataRef.roles"
            outlined clearable hide-details persistent-hint item-text="libelle" item-value="libelle")
          
          v-col(cols='12' sm='4' md='4')
            v-switch(v-model="search['user.isEnabled[=]']" label="Actif" hide-details :true-value="true" :false-value="false")
    
        v-row.align-center
          v-col(cols='12' sm='12' md='12')
            v-btn.button_color--text(@click="list()" block color="button")
              v-icon search
  
  template(v-slot:item.isEnabled='{item}')
    tr
      td(v-if='item.isEnabled')
        v-icon(small)
          | check_circle
      td(v-else)
        v-icon(small)
          | disabled_by_default
          
  template(v-slot:form="{ editedItem ,dataRef}")
    v-container
      validation-observer(ref='formObserver' v-slot='{ invalid }')
        v-card-text
          validation-provider(v-slot='{ errors }' rules='required' name="roles")
            v-select(v-model="editedItem.roles" label="Rôles *" :error-messages="errors" @change='formValidate(editedItem)' :items="dataRef.roles"
              outlined clearable hide-details persistent-hint item-text="libelle" item-value="libelle")
        v-card-text
          validation-provider(v-slot='{ errors }' rules='required' name="scope")
            v-select(v-model="editedItem.scope" label="Scopes *" :error-messages="errors" @change='formValidate(editedItem)' :items="dataRef.scopes"
              outlined clearable hide-details persistent-hint item-text="libelle" item-value="code" multiple)
        v-card-text
          validation-provider(v-slot='{ errors }' name='prénom' rules='required|max:150')
            v-text-field(v-model="editedItem.firstName" label="Prénom *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:150' name="nom")
            v-text-field(v-model="editedItem.lastName" label="Nom *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:150|email' name="email")
            v-text-field(v-model="editedItem.email" label="Email *" :error-messages="errors" @change='formValidate' outlined clearable)
          v-switch.mt-0(v-model="editedItem.isEnabled" label="Actif" @change='formValidate' )
</template>

<script>
import BasicCrud from '@/components/common/BasicCrud';
import UserService from "@/services/administration/user.service";

import {required, max} from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
} from 'vee-validate';

import fr from 'vee-validate/dist/locale/fr.json';

localize('fr', fr);
setInteractionMode('eager');
extend('required', required);
extend('max', max);

export default {
  components: {BasicCrud, ValidationProvider, ValidationObserver},
  data: () => ({
    service: UserService,
    alert: {
      show: false,
      type: "success",
      text: ""
    },
    defaultItem: {
      firstName: '',
      lastName: '',
      email: '',
      roles: '',
      isEnabled: false
    },
    defaultSearch: {
      "user.isEnabled[=]": true
    },
    formIsValid: false,
    defaultPage: {
      currentPage: 1,
      items: [],
      lastPage: 1,
      nbItemsPerPage: 5,
      nbItemsTotal: 1
    },
    headers: [
      {
        text: 'Prénom',
        align: 'start',
        value: 'person.firstName',
        sortBy: 'person.firstName',
      },
      {
        text: 'Nom',
        align: 'start',
        value: 'person.lastName',
        sortBy: 'person.lastName',
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email',
        sortBy: 'email',
      },
      {
        text: 'Role',
        align: 'start',
        value: 'roles',
        sortBy: 'roles',
      },
      {
        text: 'Actif',
        align: 'start',
        value: 'isEnabled',
        sortBy: 'isEnabled',
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],
  }),
  methods: {
    async formValidate() {
      this.formIsValid = await this.$refs.formObserver.validate();
    },
  },
};
</script>
