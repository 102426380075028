import isAuthenticated from "@/router/helpers/isAuthenticated";

import DefaultLayout from "@/components/layout/DefaultLayout";

import Demande from "@/views/administration/Demande";
import DemandeAuto from "@/views/demande/DemandeAuto";
import DemandeMRH from "@/views/demande/DemandeMRH";
import UpdateMyProfile from "@/views/account/UpdateMyProfile";

const AppRoute = {
  path: "/",
  component: DefaultLayout,
  beforeEnter: isAuthenticated,
  children: [
    {
      path: "/",
      name: "Demandes",
      component: Demande,
    },
    {
      path: "/profil",
      name: "Profile",
      component: UpdateMyProfile,
    },
    {
      path: "/demande/:id/auto",
      name: "DemandeAuto",
      component: DemandeAuto,
    },
    {
      path: "/demande/:id/mrh",
      name: "DemandeMRH",
      component: DemandeMRH,
    },
  ],
};
export default AppRoute;
