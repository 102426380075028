<template>
    <div>
        <span class="theme--light v-label">{{ label }}</span>

        <v-radio-group
            v-if="boolean"
            @change="$emit(emit, $event)"
            :value="value ? 'vrai' : value === null ? null : 'faux'"
            :rules="rules"
            row
            :append-icon="informationDialog ? 'mdi-information' : ''"
            @click:append="setInformationDialog()"
        >
            <v-radio label="Oui" value="vrai"></v-radio>
            <v-radio label="Non" value="faux"></v-radio>
        </v-radio-group>

        <v-radio-group
            v-else
            @change="$emit(emit, $event)"
            :value="value"
            :rules="rules"
            row
        >
            <v-radio
                v-for="(item, i) in items"
                :key="i"
                :label="item.libelle"
                :value="item.code"
            ></v-radio>
        </v-radio-group>
    </div>
</template>

<script>
export default {
    name: "FormRadioGroup",

    props: {
        boolean: {
            type: Boolean,
            required: true,
        },
        items: {
            type: Array,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        emit: {
            type: String,
            required: true,
        },
        value: {
            type: [Boolean, String],
            default: null,
        },
        rules: {
            type: Array,
        },
        informationDialog: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        setInformationDialog() {
            if (this.informationDialog) {
                this.$emit("setInformationDialog");
            }
        },
    },
};
</script>
