<template>
    <div class="text-center">
        <v-bottom-sheet
            v-if="component == 'bottom-sheet'"
            :value="show"
            persistent
        >
            <v-sheet class="text-center" height="200px">
                <v-btn v-if="close" class="mt-6" text color="error" @click="closeAlert">
                    Fermer
                </v-btn>
                <div class="py-3">
                    <v-alert
                        border="bottom"
                        colored-border
                        :type="type"
                        elevation="0"
                    >
                        {{ message }}
                    </v-alert>
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <v-snackbar
            v-if="component == 'snackbar'"
            :value="show"
            :timeout="-1"
            absolute
            bottom
            :color="type"
            outlined
            right
        >
            <v-sheet class="text-center" height="200px">
                <v-btn v-if="close" class="mt-6" text color="error" @click="closeAlert">
                    Fermer
                </v-btn>
                <div class="py-3">
                    <v-alert
                        border="bottom"
                        colored-border
                        :type="type"
                        elevation="0"
                    >
                        {{ message }}
                    </v-alert>
                </div>
            </v-sheet>
        </v-snackbar>

        <v-sheet
            v-if="component == 'alert' && show"
            class="text-center"
            height="200px"
        >
            <v-btn v-if="close" class="mt-6" text color="error" @click="closeAlert">
                Fermer
            </v-btn>
            <div class="py-3">
                <v-alert
                    border="bottom"
                    colored-border
                    :type="type"
                    elevation="0"
                >
                    {{ message }}
                </v-alert>
            </div>
        </v-sheet>
    </div>
</template>

<script>
export default {
    name: 'FormAlertMessage',

    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return ['success', 'info', 'warning', 'error'].includes(value);
            },
        },
        message: {
            type: String,
            required: true,
        },
        component: {
            type: String,
            required: true,
            validator: function (value) {
                return ['bottom-sheet', 'snackbar', 'alert'].includes(value);
            },
        },
        close: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        closeAlert() {
            this.$emit('close-alert');
        },
    },
};
</script>
