<template lang="pug">
validation-observer(ref='authObserver' v-slot='{ invalid }')
  v-form(@keyup.native.enter='login')
    v-card-text
      validation-provider(v-slot='{ errors }' vid='username' name='Identifiant' rules='required|email')
        v-text-field(append-icon='info' v-model='username' :error-messages='errors' label='Identifiant' name='username' required='required' :autocomplete="usernameAutocomplete")
      validation-provider(v-slot='{ errors }' vid='password' name='Mot de passe' rules='required')
        v-text-field(v-model='password' :error-messages='errors' :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append='showPassword = !showPassword'
          label='Mot de passe' name='password' required='required' :autocomplete="passwordAutocomplete")
      v-card-actions.text-center
          v-btn.button_color--text.mx-auto.px-6.py-5(:loading='loading' color='button' @click='login' :disabled='invalid') S'identifier
      v-card-actions.text-center.pb-5
          a.grey--text.mx-auto.px-6.py-5(@click='reset') Mot de passe oublié
    SnackBar(v-bind:message='message' v-bind:show='showError')
</template>
<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  localize,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import fr from "vee-validate/dist/locale/fr.json";
import SnackBar from "@/components/common/SnackBar";

localize("fr", fr);
setInteractionMode("eager");

extend("required", required);
extend("email", email);

export default {
  name: "LoginForm",
  components: {
    SnackBar,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    username: "",
    password: "",
    passwordAutocomplete: "",
    usernameAutocomplete: "username",
    showPassword: false,
    rememberMe: false,
    loading: false,
    showError: false,
    message: {
      type: "error",
      message: "Les identifiants sont invalides",
    },
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.isAuthenticated;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    } else {
      const rememberMe = localStorage.getItem("rememberMe");
      this.rememberMe = rememberMe === "1";
    }
  },
  watch: {
    seSouvenirDeMoi: function (newVal) {
      // watch it
      if (newVal) {
        this.passwordAutocomplete = "";
        this.usernameAutocomplete = "";
        localStorage.setItem("rememberMe", "1");
      } else {
        this.passwordAutocomplete = "";
        this.usernameAutocomplete = "username";
        localStorage.removeItem("rememberMe");
      }
    },
  },
  methods: {
    async login() {
      try {
        this.showError = false;
        this.loading = true;
        await this.$recaptchaLoaded();
        await this.$recaptcha("login");
        await this.$store.dispatch("auth/login", {
          username: this.username,
          password: this.password,
        });
        await this.$router.push("/");
      } catch (error) {
        console.error(error);
        this.showError = true;
      } finally {
        this.loading = false;
      }
    },
    async reset() {
      await this.$router.push("/reset/password");
    },
  },
};
</script>
