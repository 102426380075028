<template>
    <FormDialog
        @open-dialog="openSinistresMRHDialog()"
        @close-dialog="closeSinistresMRHDialog()"
        name="Sinistres au cours des 36 derniers mois"
        :width="1000"
        :dialog="dialog"
    >
        <template #dialog-content>
            <v-card-text>
                <v-form>
                    <div
                        v-for="(type, typeIndex) in sinistres"
                        :key="typeIndex"
                    >
                        <v-row align="center">
                            <v-col cols="12" sm="1" md="1">
                                <v-btn
                                    color="success"
                                    elevation="2"
                                    @click="addSinistre(typeIndex)"
                                >
                                    <v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <span class="primary--text font-weight-bold">{{
                                    type.libelle
                                }}</span>
                            </v-col>
                        </v-row>

                        <v-row
                            align="center"
                            justify="center"
                            v-for="(sinistre, sinistreIndex) in type.sinistres"
                            :key="sinistreIndex"
                        >
                            <v-col cols="12" sm="1" md="1">
                                <v-btn
                                    color="error"
                                    elevation="2"
                                    @click="
                                        deleteSinistre(typeIndex, sinistreIndex)
                                    "
                                >
                                    <v-icon>mdi-minus-thick</v-icon>
                                </v-btn>
                            </v-col>
                            
                            <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                    :rules="dateSurvenanceRules"
                                    v-model="
                                        type.sinistres[sinistreIndex]
                                            .dateSurvenance
                                    "
                                    label="Date de survenance"
                                    prepend-icon="mdi-calendar"
                                    type="date"
                                    :max="formatDate(new Date(Date.now()), 'yyyy-mm-dd')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        @click="closeSinistresMRHDialog"
                    >Valider</v-btn
                    >
                </v-card-actions>
            </v-card-text>
        </template>
    </FormDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormDialog from "@/components/FormDialog";

export default {
    name: "FormSinistresMRHDialog",

    components: {
        FormDialog,
    },

    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    beforeMount: function () {
        this.initSinistres();
    },

    data() {
        return {
            sinistres: [],
            dateSurvenanceRules: [
                (v) => !!v || "La date de survenance est obligatoire",
            ],
        };
    },

    computed: {
        ...mapGetters("form", ["data", "formData"]),
    },

    watch: {
        sinistres: {
            handler: function (newValue) {
                let sinistres = [];
                for (const typeSinistre of newValue) {
                    if (typeSinistre.sinistres.length > 0) {
                        for (const sinistre of typeSinistre.sinistres) {
                            sinistres.push(sinistre);
                        }
                    }
                }

                this.setModel("sinistres", sinistres);
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions("form", ["setDemande", "setDemandeMRH"]),

        setModel(field, value) {
            this.setDemandeMRH({
                field: field,
                value: value,
            });
        },

        openSinistresMRHDialog() {
            this.$emit("open-sinistres-mrh-dialog");
        },

        closeSinistresMRHDialog() {
            this.$emit("close-sinistres-mrh-dialog");
        },

        initSinistres() {
            let sinistres = JSON.parse(
                JSON.stringify(this.data.types_sinistre)
            );

            for (const typeSinistre of sinistres) {
                typeSinistre["sinistres"] = [];
            }

            this.sinistres = sinistres;
        },

        addSinistre(typeIndex) {
            this.sinistres[typeIndex].sinistres.push({
                typeSinistre: this.sinistres[typeIndex].code,
                dateSurvenance: null,
            });
        },

        deleteSinistre(typeIndex, sinistreIndex) {
            this.sinistres[typeIndex].sinistres.splice(sinistreIndex, 1);
        },

        formatDate(date, format) {
            const map = {
                mm: this.addZeroInFrontOfFigure(date.getMonth() + 1),
                dd: this.addZeroInFrontOfFigure(date.getDate()),
                yyyy: date.getFullYear()
            }
            console.log(date.getFullYear());

            return format.replace(/mm|dd|yyyy/gi, matched => map[matched])
        },

        addZeroInFrontOfFigure(figure) {
            return ("0" + figure).slice(-2);
        }
    }
};
</script>