import Vue from "vue";
import IdleVue from "idle-vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6Lfbt8EcAAAAAHNQD-y0N-mfBx-EhuOZJWbNJgll" });

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1800000, // ALZRAD-40: pour la déconnexion au bout de 30 minutes d'inactivité
  startAtIdle: false,
});

Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
