// initial state
const state = () => ({
  data: null,
  formData: {
    demande: {
      consentement_infos: null,
      qr_code_code: null,
      garantie: null,
    },
    demande_coordonnees: {
      client_allianz: null,
      nom: null,
      prenom: null,
      email: null,
      tel: null,
      adresse1: null,
      adresse2: null,
      code_postal: null,
      commune: null,
      profession: null,
      situation_familiale: null,
      civilite: null,
      justificatif_identite: null,
      second_conducteur: null,
    },
    demande_assur_auto: {
      bonus_malus: null,
      mode_financement_code: null,
      type_vehicule_code: null,
      justificatif_permis_recto: null,
      justificatif_permis_verso: null,
      justificatif_vehicule: null,
      justificatif_carte_grise_verso: null,
      justificatif_controle_technique: null,
      justificatif_releve_info: null,
      second_conducteur: null,
      sinistres: null,
    },
    demande_mrh: {
      superficie: null,
      nb_pieces: null,
      // valeur: null,
      valeur_contenu: null,
      superficie_sup: null,
      distance_mer: null,
      superficie_dependances: null,
      installations_exterieures: null,
      dommages_electriques: null,
      individuelle_scolaire: null,
      piscine: null,
      energies_renouvelables: null,
      type_residence: null,
      type_bien: null,
      type_occupation: null,
      sinistres: null,
    },
  },
});

// getters
const getters = {
    data: (state) => {
        return state.data;
    },
    formData: (state) => {
        return state.formData;
    },
};

// actions
const actions = {
    setData({ commit }, payload) {
        commit('setData', payload);
    },
    setDemande({ commit }, payload) {
        commit('setFormData', {
            fieldType: 'demande',
            field: payload.field,
            value: payload.value,
            recursive: false,
        });
    },
    setDemandeCoordonnees({ commit }, payload) {
        commit('setFormData', {
            fieldType: 'demande_coordonnees',
            field: payload.field,
            value: payload.value,
            recursive: false,
        });
    },
    setDemandeCoordonneesRecursive({ commit }, payload) {
        commit('setFormData', {
            fieldType: 'demande_coordonnees',
            field: payload.field,
            value: payload.value,
            recursive: true,
        });
    },
    setDemandeAssurAuto({ commit }, payload) {
        commit('setFormData', {
            fieldType: 'demande_assur_auto',
            field: payload.field,
            value: payload.value,
            recursive: false,
        });
    },
    setDemandeAssurAutoRecursive({ commit }, payload) {
        commit('setFormData', {
            fieldType: 'demande_assur_auto',
            field: payload.field,
            value: payload.value,
            recursive: true,
        });
    },
    setDemandeMRH({ commit }, payload) {
        commit('setFormData', {
            fieldType: 'demande_mrh',
            field: payload.field,
            value: payload.value,
            recursive: false,
        });
    },
    resetFormData({ commit }) {
        commit('resetFormData');
    },
    resetFormDataSecondConducteur({ commit }, payload) {
        commit('resetFormDataSecondConducteur', payload);
    },
};

// mutations
const mutations = {
    setData(state, payload) {
        state.data = payload.data;
    },
    setFormData(state, payload) {
        if (
            (payload.fieldType == 'demande_coordonnees' ||
                payload.fieldType == 'demande_assur_auto') &&
            payload.recursive
        ) {
            state.formData[payload.fieldType]['second_conducteur'][
                payload.field
            ] = payload.value;
        } else {
            state.formData[payload.fieldType][payload.field] = payload.value;
        }
    },
    resetFormData(state) {
        state.formData = {
          demande: {
            consentement_infos: null,
            qr_code_code: state.formData.demande.qr_code_code,
            garantie: null,
          },
          demande_coordonnees: {
            client_allianz: null,
            nom: null,
            prenom: null,
            email: null,
            tel: null,
            adresse1: null,
            adresse2: null,
            code_postal: null,
            commune: null,
            profession: null,
            situation_familiale: null,
            civilite: null,
            justificatif_identite: null,
            second_conducteur: null,
          },
          demande_assur_auto: {
            bonus_malus: null,
            mode_financement_code: null,
            type_vehicule_code: null,
            justificatif_permis_recto: null,
            justificatif_permis_verso: null,
            justificatif_vehicule: null,
            justificatif_carte_grise_verso: null,
            justificatif_controle_technique: null,
            justificatif_releve_info: null,
            second_conducteur: null,
            sinistres: null,
          },
          demande_mrh: {
            superficie: null,
            nb_pieces: null,
            // valeur: null,
            valeur_contenu: null,
            superficie_sup: null,
            distance_mer: null,
            superficie_dependances: null,
            installations_exterieures: null,
            dommages_electriques: null,
            individuelle_ccolaire: null,
            piscine: null,
            energies_renouvelables: null,
            type_residence: null,
            type_bien: null,
            type_occupation: null,
          },
        };
    },
    resetFormDataSecondConducteur(state, payload) {
        if (payload.secondConducteur) {
            state.formData.demande_coordonnees.second_conducteur = {
                client_allianz: null,
                nom: null,
                prenom: null,
                email: null,
                tel: null,
                adresse1: null,
                adresse2: null,
                code_postal: null,
                commune: null,
                profession: null,
                situation_familiale: null,
                civilite: null,
            };

            state.formData.demande_assur_auto.second_conducteur = {
                bonus_malus: null,
                justificatif_permis_recto: null,
                justificatif_permis_verso: null,
                justificatif_releve_info: null,
                sinistres: null
            };
        } else {
            state.formData.demande_coordonnees.second_conducteur = null;
            state.formData.demande_assur_auto.second_conducteur = null;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
