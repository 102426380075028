<template>
    <v-banner align="left">
        <v-img
            :src="require('@/assets/logo.svg')"
            contain
            height="30"
            transition="scale-transition"
            position="left center"
        />

        <span class="primary--text">Didier RADAVIDRA</span>
    </v-banner>
</template>

<script>
export default {
    name: 'FormHeader',
};
</script>
