<template>
    <FormDialog
        @open-dialog="openSinistresAutoDialog()"
        @close-dialog="closeSinistresAutoDialog()"
        name="Sinistralité des conducteurs sur les 36 derniers mois"
        :width="1000"
        :dialog="dialog"
    >
        <template #dialog-content>
            <v-card-text>
                <v-form v-if="demande.sinistres.length > 0">
                    <div
                        v-for="(type, typeIndex) in sinistres"
                        :key="typeIndex"
                    >
                        <v-row v-if="type.sinistres.length > 0" align="center">
                            <v-col cols="12" sm="2" md="2">
                                <span class="primary--text font-weight-bold">{{
                                    type.libelle
                                }}</span>
                            </v-col>
                        </v-row>

                        <v-row
                            align="center"
                            justify="center"
                            v-for="(sinistre, sinistreIndex) in type.sinistres"
                            :key="sinistreIndex"
                        >
                            <v-col cols="12" sm="3" md="3">
                                <v-select
                                    v-model="
                                        type.sinistres[sinistreIndex]
                                            .typeConducteur
                                    "
                                    :items="referentielDemande.types_conducteur"
                                    item-text="libelle"
                                    item-value="code"
                                    label="Type de conducteur*"
                                    :title="
                                        referentielDemande.types_conducteur.find(
                                            (item) =>
                                                item.code ===
                                                type.sinistres[sinistreIndex]
                                                    .typeConducteur
                                        ).libelle
                                    "
                                    readonly
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                                <v-text-field
                                    v-model="
                                        type.sinistres[sinistreIndex]
                                            .dateSurvenance
                                    "
                                    label="Date de survenance"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3" md="3">
                                <v-select
                                    v-model="
                                        type.sinistres[sinistreIndex]
                                            .responsabilite
                                    "
                                    :items="referentielDemande.responsabilites"
                                    item-text="libelle"
                                    item-value="libelle"
                                    label="Responsabilité*"
                                    suffix="%"
                                    readonly
                                ></v-select>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>

                <FormAlertMessage
                    v-else
                    :show="true"
                    type="info"
                    message="Aucun sinistre déclaré."
                    component="alert"
                    :close="false"
                />
            </v-card-text>
        </template>
    </FormDialog>
</template>

<script>
import FormDialog from "@/components/FormDialog";
import FormAlertMessage from "@/components/FormAlertMessage";

export default {
    name: "DemandeAutoSinistresAutoDialog",

    components: {
        FormDialog,
        FormAlertMessage,
    },

    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
        demande: {
            type: Object,
            required: true,
        },
        referentielDemande: {
            type: Object,
            required: true,
        },
    },

    beforeUpdate: function () {
        this.initSinistres();
    },

    data() {
        return {
            sinistres: [],
        };
    },

    methods: {
        openSinistresAutoDialog() {
            this.$emit("open-sinistres-auto-dialog");
        },

        closeSinistresAutoDialog() {
            this.$emit("close-sinistres-auto-dialog");
        },

        initSinistres() {
            let sinistres = JSON.parse(
                JSON.stringify(this.referentielDemande.types_sinistre)
            );

            for (const index in sinistres) {
                const typeSinistre = sinistres[index];
                sinistres[index]["sinistres"] = [];

                for (const sinistre of this.demande.sinistres) {
                    if (typeSinistre.code === sinistre.typeSinistre) {
                        sinistres[index]["sinistres"].push(sinistre);
                    }
                }
            }

            this.sinistres = sinistres;
        },
    },
};
</script>