<template>
    <router-view />
</template>

<script>
import setupInterceptors from "@/helpers/interceptors";

export default {
    name: "App",

    components: {},

    created() {
        setupInterceptors();
    },

    computed: {
        isIdle() {
            return this.$store.state.idleVue.isIdle;
        },
    },

    watch: {
        async isIdle(newValue) {
            if (newValue && this.$store.state.auth.isAuthenticated) {
                this.$store.dispatch("auth/logout");
                this.$router.go();
            }
        },
    },
};
</script>
