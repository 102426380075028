import axios from "axios";

const API_URL = "/api/admin/vendeurs";

class VendeurService {
  async getReferentials() {
    const res = await axios({
      method: "get",
      url: API_URL + "/referentials",
    });
    return res.data;
  }

  async create(vendeur) {
    const res = await axios({
      method: "post",
      url: API_URL,
      data: vendeur,
    });
    return res.data;
  }

  async getById(idVendeur) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idVendeur,
    });
    return res.data;
  }

  async list(page, nbItemsPerPage, sortOption, search) {
    const res = await axios({
      method: "get",
      url: API_URL,
      params: { page, nbItemsPerPage, sortOption, search },
    });
    return res.data;
  }

  async remove(idVendeur) {
    const res = await axios({
      method: "delete",
      url: API_URL + "/" + idVendeur,
    });
    return res.data;
  }

  async update(idVendeur, vendeur) {
    const res = await axios({
      method: "put",
      url: API_URL + "/" + idVendeur,
      data: vendeur,
    });
    return res.data;
  }

  async afficherQRCode(idVendeur) {
    const res = await axios({
      method: "get",
      url: API_URL + "/" + idVendeur + '/qr-code',
    });
    return res.data;
  }
}

export default new VendeurService();
