<template>
    <div>
        <v-file-input
            v-model="identite"
            :rules="identiteRules && fileRules"
            label="Pièce d'identité*"
            hint="(CNI ou Passeport)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "FormMRHDocumentsSection",

    data() {
        return {
            identiteRules: [
                (v) => !!v || "Une pièce d'identité valide est obligatoire",
            ],
            fileRules: [
                (v) =>
                    !v ||
                    v.size <= 8 * 1024 * 1024 ||
                    "La taille du fichier ne doit pas dépasser 8 Mo",
            ],
        };
    },

    computed: {
        ...mapGetters("form", ["formData"]),
        identite: {
            get() {
                return this.formData.demande_coordonnees.justificatif_identite;
            },
            set(value) {
                this.setDemandeCoordonnees({
                    field: "justificatif_identite",
                    value: value,
                });
            },
        },
    },

    methods: {
        ...mapActions("form", ["setDemandeCoordonnees"]),
    },
};
</script>
