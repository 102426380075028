<template>
    <FormDialog
        @open-dialog="openDefinitionValeurContenuDialog()"
        @close-dialog="closeDefinitionValeurContenuDialog()"
        name=""
        :width="1000"
        :dialog="dialog"
    >
        <template #dialog-content>
            <v-card-text>
                <div>
                    <h2>LE CONTENU DE VOTRE HABITATION</h2>
                    <hr />
                    <br />
                    <p>
                        Il comprend, quel qu’en soit le propriétaire, l’ensemble
                        des meubles, matériels, vêtements et objets se trouvant
                        dans les locaux assurés y compris :
                    </p>
                    <ul>
                        <li>les animaux,</li>
                        <li>
                            les véhicules motorisés (ex. : jouets d’enfants...)
                            dont la vitesse maximale autorisée ne peut pas
                            dépasser 8 km/h,
                        </li>
                        <li>
                            les motoculteurs et tondeuses autoportées (puissance
                            maximale 20 CV),
                        </li>
                        <li>
                            les robots-tondeuses ainsi que les fauteuils
                            roulants, électriques ou non, des personnes
                            handicapées,
                        </li>
                        <li>les objets de valeurs,</li>
                        <li>les fonds et valeurs.</li>
                    </ul>
                    <br />
                    <p>Ne sont pas assurés :</p>
                    <ul>
                        <li>
                            Les véhicules à moteur (autres que ceux désignés
                            ci-dessus).
                        </li>
                        <li>Les remorques de plus de 750 kg.</li>
                        <li>Les caravanes ainsi que leur contenu.</li>
                        <li>Les marchandises professionnelles.</li>
                        <li>
                            Les biens appartenant aux locataires ou
                            sous-locataires si vous êtes loueur en meublé.
                        </li>
                    </ul>
                </div>
            </v-card-text>
        </template>
    </FormDialog>
</template>

<script>
import FormDialog from "@/components/FormDialog";

export default {
    name: "FormDefinitionValeurContenuDialog",

    components: {
        FormDialog,
    },

    props: {
        dialog: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        openDefinitionValeurContenuDialog() {
            this.$emit("open-definition-valeur-contenu-dialog");
        },

        closeDefinitionValeurContenuDialog() {
            console.log("OK");
            this.$emit("close-definition-valeur-contenu-dialog");
        },
    },
};
</script>