<template>
    <div>
        <FormGenderSelect
            @setCivilite="setCivilite"
            :genders="genders"
            :value="civilite"
            :rules="civiliteRules"
        />

        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="prenom"
                    :rules="prenomRules"
                    maxlength="25"
                    counter="25"
                    hint="Veuillez renseigner votre prénom"
                    label="Prénom*"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="nom"
                    :rules="nomRules"
                    maxlength="25"
                    counter="25"
                    hint="Veuillez renseigner votre nom"
                    label="Nom*"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-select
                    v-model="situationFamiliale"
                    :rules="situationFamilialeRules"
                    :items="data.situations_familiale"
                    item-text="libelle"
                    item-value="code"
                    label="Situation de famille*"
                    hint="Veuillez sélectionner votre situation de famille"
                ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-select
                    v-model="profession"
                    :rules="professionRules"
                    :items="data.professions"
                    item-text="libelle"
                    item-value="code"
                    label="Profession*"
                    hint="Veuillez sélectionner votre profession"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="adresse1"
                    :rules="adresse1Rules"
                    maxlength="40"
                    counter="40"
                    label="Adresse*"
                    hint="Veuillez renseigner votre adresse"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="adresse2"
                    maxlength="25"
                    counter="25"
                    label="Complément d'adresse"
                    hint="Veuillez renseigner un complément d'adresse"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="codePostal"
                    :rules="codePostalRules"
                    maxlength="5"
                    counter="5"
                    label="Code postal*"
                    hint="Veuillez renseigner votre code postal"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="commune"
                    :rules="communeRules"
                    maxlength="255"
                    counter="255"
                    label="Ville*"
                    hint="Veuillez renseigner votre ville"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    maxlength="40"
                    counter="40"
                    label="Adresse e-mail*"
                    hint="Veuillez renseigner votre adresse e-mail"
                    prepend-icon="mdi-at"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="tel"
                    :rules="telRules"
                    maxlength="10"
                    counter="10"
                    hint="Veuillez renseigner votre numéro de téléphone"
                    label="Téléphone*"
                    prepend-icon="mdi-phone"
                ></v-text-field>
            </v-col>
        </v-row>

        <slot name="bonus-malus"></slot>

        <v-row class="mt-10 justify-center">
            <FormRadioGroup
                @clientAllianz="setClientAllianz"
                :boolean="true"
                label="Déjà client ?*"
                emit="clientAllianz"
                :value="clientAllianz"
                :rules="clientAllianzRules"
            />
        </v-row>

        <v-row
            v-if="typeDemande == 'AUTO' && !recursive"
            class="mt-10 justify-center"
        >
            <v-switch
                @change="toggleSecondConducteur"
                v-model="secondConducteur"
                inset
                label="J'ai un second conducteur"
            ></v-switch>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormGenderSelect from '@/components/FormGenderSelect';
import FormRadioGroup from '@/components/FormRadioGroup';

export default {
    name: 'FormContactSection',

    components: {
        FormGenderSelect,
        FormRadioGroup,
    },

    props: {
        typeDemande: {
            type: String,
            required: true,
            validator: function (value) {
                return ['AUTO', 'MRH'].includes(value);
            },
        },
        recursive: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            secondConducteur: false,
            civiliteRules: [(v) => !!v || 'La civilité est obligatoire'],
            prenomRules: [
                (v) => !!v || 'Le prénom est obligatoire',
                (v) => /^[^0-9]+$/.test(v) || 'Le prénom ne peut contenir de caractères numériques'
            ],
            nomRules: [
                (v) => !!v || 'Le nom est obligatoire',
                (v) => /^[^0-9]+$/.test(v) || 'Le nom ne peut contenir de caractères numériques'
            ],
            situationFamilialeRules: [
                (v) => !!v || 'La situation familiale est obligatoire',
            ],
            professionRules: [(v) => !!v || 'La profession est obligatoire'],
            adresse1Rules: [(v) => !!v || "L'adresse est obligatoire"],
            codePostalRules: [
                (v) => !!v || 'Le code postal est obligatoire',
                (v) => /[0-9]{5}/.test(v) || "Le code postal n'est pas valide",
            ],
            communeRules: [
                (v) => !!v || 'La commune est obligatoire',
                (v) => /^[^0-9]+$/.test(v) || 'La commune ne peut contenir de caractères numériques'
            ],
            emailRules: [
                (v) => !!v || "L'adresse e-mail est obligatoire",
                (v) =>
                    /.+@.+\..+/.test(v) || "L'adresse e-mail n'est pas valide",
            ],
            telRules: [
                (v) => !!v || 'Le téléphone est obligatoire',
                (v) => /^[0-9]{10}/.test(v) || "Le téléphone n'est pas valide",
            ],
            clientAllianzRules: [(v) => !!v || 'Une réponse est obligatoire'],
            genders: [],
        };
    },

    computed: {
        ...mapGetters('form', ['data', 'formData']),
        civilite: {
            get() {
                return this.getModel('civilite');
            },
        },
        prenom: {
            get() {
                return this.getModel('prenom');
            },
            set(value) {
                this.setModel('prenom', value);
            },
        },
        nom: {
            get() {
                return this.getModel('nom');
            },
            set(value) {
                this.setModel('nom', value);
            },
        },
        situationFamiliale: {
            get() {
                return this.getModel('situation_familiale');
            },
            set(value) {
                this.setModel('situation_familiale', value);
            },
        },
        profession: {
            get() {
                return this.getModel('profession');
            },
            set(value) {
                this.setModel('profession', value);
            },
        },
        adresse1: {
            get() {
                return this.getModel('adresse1');
            },
            set(value) {
                this.setModel('adresse1', value);
            },
        },
        adresse2: {
            get() {
                return this.getModel('adresse2');
            },
            set(value) {
                this.setModel('adresse2', value);
            },
        },
        codePostal: {
            get() {
                return this.getModel('code_postal');
            },
            set(value) {
                this.setModel('code_postal', value);
            },
        },
        commune: {
            get() {
                return this.getModel('commune');
            },
            set(value) {
                this.setModel('commune', value);
            },
        },
        email: {
            get() {
                return this.getModel('email');
            },
            set(value) {
                this.setModel('email', value);
            },
        },
        tel: {
            get() {
                return this.getModel('tel');
            },
            set(value) {
                this.setModel('tel', value);
            },
        },
        clientAllianz: {
            get() {
                return this.getModel('client_allianz');
            },
        },
    },

    watch: {
        formData(newValue) {
            if (
                newValue.demande_coordonnees.second_conducteur === null &&
                newValue.demande_assur_auto.second_conducteur === null
            ) {
                this.secondConducteur = false;
                this.toggleSecondConducteur(false);
            }
        },
    },

    beforeMount: function () {
        this.getGenders();
    },

    methods: {
        ...mapActions('form', [
            'setDemandeCoordonnees',
            'setDemandeCoordonneesRecursive',
            'resetFormDataSecondConducteur',
        ]),
        toggleSecondConducteur(value) {
            this.resetFormDataSecondConducteur({ secondConducteur: value });
            this.$emit('toggleSecondConducteur', value);
        },
        getModel(field) {
            if (this.recursive) {
                return this.formData.demande_coordonnees.second_conducteur[
                    field
                ];
            } else {
                return this.formData.demande_coordonnees[field];
            }
        },
        setModel(field, value) {
            if (this.recursive) {
                this.setDemandeCoordonneesRecursive({
                    field: field,
                    value: value,
                });
            } else {
                this.setDemandeCoordonnees({
                    field: field,
                    value: value,
                });
            }
        },
        getGenders() {
            let genders = [];

            for (const civilite of this.data.civilites) {
                let gender = {
                    name: civilite.libelle,
                    code: civilite.code,
                    icon: '',
                    order: 0,
                };

                switch (civilite.code) {
                    case 'MADAME':
                        gender.icon = 'mdi-face-woman';
                        gender.order = 1;
                        break;

                    case 'MONSIEUR':
                        gender.icon = 'mdi-face-man';
                        gender.order = 2;
                        break;

                    default:
                        break;
                }

                genders.push(gender);
            }

            this.genders = genders;
        },
        setCivilite(payload) {
            if (this.recursive) {
                this.setDemandeCoordonneesRecursive({
                    field: 'civilite',
                    value: payload,
                });
            } else {
                this.setDemandeCoordonnees({
                    field: 'civilite',
                    value: payload,
                });
            }
        },
        setClientAllianz(payload) {
            if (this.recursive) {
                this.setDemandeCoordonneesRecursive({
                    field: 'client_allianz',
                    value: payload == 'vrai',
                });
            } else {
                this.setDemandeCoordonnees({
                    field: 'client_allianz',
                    value: payload == 'vrai',
                });
            }
        },
    },
};
</script>
