<template>
    <v-banner
        color="primary"
        single-line
        align="center"
        justify="center"
        class="mb-10 fill-height"
    >
        <span class="white--text font-weight-bold">
            {{ title }}
        </span>
        <v-icon color="white">>{{ iconName }}</v-icon>
    </v-banner>
</template>

<script>
export default {
    name: 'FormTitle',

    props: {
        title: {
            type: String,
            required: true,
        },
        iconName: {
            type: String,
            required: true,
        },
    },
};
</script>