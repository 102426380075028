<template lang="pug">
v-menu(:close-on-content-click='profilMenu' open-on-click bottom left offset-y)
  template(v-slot:activator='{ on, attrs }')
    v-btn.mx-4(icon v-bind='attrs' v-on='on')
      v-avatar.button_light.button_color--text.text-uppercase(size='36')
        | {{ user.initials }}
  v-card
    v-list
      v-list-item
        v-list-item-title
          | Bonjour&nbsp;
          b {{ user.fullName }}
    v-divider
    v-list(:subheader='true' no-action)
      v-subheader Mon compte
      v-list-item(router :to="'/profil'" @click='profilMenu = true')
        v-list-item-icon
          v-icon person
        v-list-item-title Mon profil
      v-list-item(v-on:click='logout')
        v-list-item-icon
          v-icon logout
        v-list-item-title Se déconnecter
    v-divider
    v-list(:subheader='true')
      v-subheader Préférences
      v-list-item
        v-list-item-action
          v-switch(dense inset hide-details :value='darkMode' @click='profilMenu = false' @change='toggleDarkMode' :label='`Activer le ${switchDarkModeLabel} mode`')
    v-divider
    v-list(:subheader='true' no-action v-if="this.$store.state.auth.data.user.isAdmin")
      v-subheader Administration
      v-list-item(router :to="'/admin/users'" @click='profilMenu = true')
        v-list-item-icon
          v-icon lock
        v-list-item-title Administration
</template>

<script>
export default {
  name: "AccountMenu",
  data: () => ({
    darkMode: false,
    profilMenu: true,
    user: null,
  }),
  computed: {
    switchDarkModeLabel: function () {
      return this.darkMode ? "Light" : "Dark";
    },
  },
  created() {
    this.user = this.$store.state.auth.data.user;
  },
  mounted() {
    const theme = localStorage.getItem("darkMode");

    // Check if the user has set the theme state before
    if (theme) {
        if (theme === "true") {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }
    } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem(
            "darkMode",
            this.$vuetify.theme.dark.toString()
        );
    }
  },
  methods: {
    toggleDarkMode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = !this.darkMode;
      this.emitToParent();
      localStorage.setItem("darkMode", this.$vuetify.theme.dark.toString());
    },
    // Define the method that emits data to the parent as the first parameter to `$emit()`.
    // This is referenced in the <template> call in the parent. The second parameter is the payload.
    emitToParent() {
      this.$emit("childToParent", this.darkMode);
    },
    logout() {
      window.location.href = "/logout";
    },
  },
};
</script>
