<template>
  <v-container fluid id="form-layout">
    <FormHeader />

    <v-form ref="form" v-model="valid" lazy-validation>
      <slot name="form-content"></slot>

      <FormFooter
        v-if="footer"
        @validate="validate"
        :validation-loading="validationLoading"
      />
    </v-form>

    <FormAlertMessage
      @open-alert="openAlert"
      @close-alert="closeAlert"
      v-bind="alert"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DemandeAutoService from "@/services/demande-auto.service";
import DemandeMRHService from "@/services/demande-mrh.service";

import FormFooter from "@/components/FormFooter";
import FormHeader from "@/components/FormHeader";
import FormAlertMessage from "@/components/FormAlertMessage.vue";

export default {
  name: "FormLayout",

  components: { FormHeader, FormFooter, FormAlertMessage },

  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      valid: true,
      validationLoading: false,
      alert: {
        show: false,
        type: "success",
        message: "",
        component: "bottom-sheet",
        close: true,
      },
    };
  },

  computed: {
    ...mapGetters("form", ["data", "formData"]),
    formType() {
      return this.data ? this.data.type_demande : null;
    },
  },

  methods: {
    ...mapActions("form", ["resetFormData"]),
    async validate() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.validationLoading = true;

        let service = null;
        switch (this.formType) {
          case "AUTO":
            service = DemandeAutoService;
            break;

          case "MRH":
            service = DemandeMRHService;
            break;
        }

        await service
          .creer(this.getFormData())
          .then((response) => {
            this.showMessage({
              type: "success",
              message: response.message,
              close: false,
            });

            // setTimeout(
            //   function () {
            //     this.$router.go();
            //   }.bind(this),
            //   2500
            // );
          })
          .catch((response) => {
            let message = response.response.data.message
              ? response.response.data.message
              : response.message;

            this.showMessage({
              type: "error",
              message: message,
              close: true,
            });
          });

        this.validationLoading = false;
      }
    },
    showMessage(props) {
      this.alert.type = props.type;
      this.alert.message = props.message;
      this.alert.close = props.close;
      this.openAlert();
    },
    openAlert() {
      this.alert.show = true;
    },
    closeAlert() {
      this.alert.show = false;
    },
    getFormData() {
      let formData = new FormData();
      formData.append("demande", JSON.stringify(this.formData.demande));
      formData.append(
        "demande_coordonnees",
        JSON.stringify(this.formData.demande_coordonnees)
      );
      formData.append(
        "demande_assur_auto",
        JSON.stringify(this.formData.demande_assur_auto)
      );
      formData.append("demande_mrh", JSON.stringify(this.formData.demande_mrh));

      if (this.formData.demande_coordonnees.justificatif_identite) {
        formData.append(
          "justificatif_identite",
          this.formData.demande_coordonnees.justificatif_identite
        );
      }

      if (this.formData.demande_assur_auto.justificatif_permis_recto) {
        formData.append(
          "justificatif_permis_recto",
          this.formData.demande_assur_auto.justificatif_permis_recto
        );
      }

      if (this.formData.demande_assur_auto.justificatif_permis_verso) {
        formData.append(
          "justificatif_permis_verso",
          this.formData.demande_assur_auto.justificatif_permis_verso
        );
      }

      if (
        this.formData.demande_assur_auto.second_conducteur &&
        this.formData.demande_assur_auto.second_conducteur.justificatif_permis_recto
      ) {
        formData.append(
          "justificatif_permis_recto_second_conducteur",
          this.formData.demande_assur_auto.second_conducteur.justificatif_permis_recto
        );
      }

      if (
        this.formData.demande_assur_auto.second_conducteur &&
        this.formData.demande_assur_auto.second_conducteur.justificatif_permis_verso
      ) {
        formData.append(
          "justificatif_permis_verso_second_conducteur",
          this.formData.demande_assur_auto.second_conducteur.justificatif_permis_verso
        );
      }

      if (this.formData.demande_assur_auto.justificatif_vehicule) {
        formData.append(
          "justificatif_vehicule",
          this.formData.demande_assur_auto.justificatif_vehicule
        );
      }

      if (this.formData.demande_assur_auto.justificatif_carte_grise_verso) {
        formData.append(
          "justificatif_carte_grise_verso",
          this.formData.demande_assur_auto.justificatif_carte_grise_verso
        );
      }

      if (this.formData.demande_assur_auto.justificatif_controle_technique) {
        formData.append(
          "justificatif_controle_technique",
          this.formData.demande_assur_auto.justificatif_controle_technique
        );
      }

      if (this.formData.demande_assur_auto.justificatif_releve_info) {
        formData.append(
          "justificatif_releve_info",
          this.formData.demande_assur_auto.justificatif_releve_info
        );
      }

      if (
        this.formData.demande_assur_auto.second_conducteur &&
        this.formData.demande_assur_auto.second_conducteur
          .justificatif_releve_info
      ) {
        formData.append(
          "justificatif_releve_info_second_conducteur",
          this.formData.demande_assur_auto.second_conducteur
            .justificatif_releve_info
        );
      }

      return formData;
    },
  },
};
</script>

<style lang="scss">
#form-layout {
  height: 100%;
}
</style>
