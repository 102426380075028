import { render, staticRenderFns } from "./BasicCrud.vue?vue&type=template&id=3c436b1f&scoped=true&lang=pug&"
import script from "./BasicCrud.vue?vue&type=script&lang=js&"
export * from "./BasicCrud.vue?vue&type=script&lang=js&"
import style0 from "./BasicCrud.vue?vue&type=style&index=0&id=3c436b1f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c436b1f",
  null
  
)

export default component.exports