<template>
    <div>
        <v-row class="mb-10 justify-center">
            <FormRadioGroup
                @consentementInfos="setConsentementInfos"
                :boolean="true"
                label="Acceptez-vous de recevoir des offres commerciales et
                personnalisées par le groupe Allianz ?*"
                emit="consentementInfos"
                :value="consentementInfos"
                :rules="consentementInfosRules"
            />
        </v-row>

        <v-row class="mb-10 justify-center">
            <v-btn
                color="primary"
                elevation="2"
                :loading="validationLoading"
                x-large
                @click="validate"
                >Soumettre</v-btn
            >
        </v-row>

        <v-row class="justify-center">
            <v-alert class="primary--text"
                >Nous collectons vos données personnelles pour vous recontacter
                dans le cadre de votre projet Auto. Pour en savoir plus sur la
                protection de vos données lien <a href="https://www.allianz.fr/assurance-particulier/protection-donnees-personnelles.html/" target="_blank">en savoir plus</a>.</v-alert
            >
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import FormRadioGroup from '@/components/FormRadioGroup';

export default {
    name: 'FormFooter',

    components: { FormRadioGroup },

    props: {
        validationLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            consentementInfosRules: [
                (v) => !!v || 'Une réponse est obligatoire',
            ],
        };
    },

    computed: {
        ...mapGetters('form', ['formData']),
        consentementInfos: {
            get() {
                return this.formData.demande.consentement_infos;
            },
        },
    },

    methods: {
        ...mapActions('form', ['setDemande']),
        setConsentementInfos(payload) {
            this.setDemande({
                field: 'consentement_infos',
                value: payload == 'vrai',
            });
        },
        validate() {
            this.$emit('validate');
        },
    },
};
</script>
