<template lang="pug">
  v-row(justify='center')
    v-col(cols='12' sm='4')
      v-card
        v-card-title.justify-center
          v-img(lazy-src='' max-height='75' max-width='75' :src='require("@/assets/images/logo_white.png")')
        v-card-subtitle.mb-0.pb-0.pt-5.text-center
          span.text-h6 Bienvenue
          br
          | Merci de bien vouloir vous identifier
          LoginForm
</template>

<style>
.grecaptcha-badge {
  visibility: hidden !important;
}
</style>

<script>
import LoginForm from "@/components/login/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.isAuthenticated;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  data: () => ({
    loadingCheckAuth: false,
    errors: [],
    recaptchaToken: "",
    checkbox: false,
  }),
};
</script>
