<template>
    <div>
        <v-banner color="#F7F7F7" single-line align="center" class="mb-10">
            <span class="primary--text">{{ title }}</span>
        </v-banner>

        <slot></slot>

        <v-row class="ma-10 justify-end">
            <span class="primary--text font-italic"
                >* Information obligatoire</span
            >
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'FormCategory',

    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
