<template>
    <div>
        <FormTitle
            title="Demande de contact multirisque habitation"
            iconName="mdi-home"
        />

        <FormCategory title="Mes coordonnées">
            <FormContactSection type-demande="MRH" />
        </FormCategory>

        <FormCategory title="Mon habitation">
            <FormMRHSection />
        </FormCategory>

        <FormCategory title="Justificatif à fournir">
            <FormMRHDocumentsSection />
        </FormCategory>
    </div>
</template>

<script>
import FormTitle from '@/components/FormTitle';
import FormCategory from '@/components/FormCategory';
import FormContactSection from '@/components/FormContactSection';
import FormMRHSection from '@/components/FormMRHSection';
import FormMRHDocumentsSection from '@/components/FormMRHDocumentsSection';

export default {
    name: 'FormMRH',

    components: {
        FormTitle,
        FormContactSection,
        FormMRHSection,
        FormMRHDocumentsSection,
        FormCategory,
    },

    data() {
        return {};
    },
};
</script>
