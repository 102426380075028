import axios from "axios";

const API_URL = "/api";
const API_DEMANDE_URL = API_URL + "/demandes/auto";
const API_ADMIN_URL = API_URL + "/admin/demandes/auto";

class DemandeAutoService {
  async afficher(idDemande) {
    const res = await axios({
      method: "get",
      url: API_ADMIN_URL + "/" + idDemande,
    });
    return res.data;
  }

  async creer(demande) {
    const res = await axios({
      method: "post",
      url: API_DEMANDE_URL,
      data: demande,
    });
    return res.data;
  }
}

export default new DemandeAutoService();
