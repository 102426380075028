<template lang="pug">
v-navigation-drawer.drawer_background(v-model='drawer' app clipped)
  v-list-item
    v-list-item-content
      v-list-item-title.drawer_color--text.text-h6 Administration
  v-divider
  v-list(dense)
    v-list-item(v-for="item in items", :key="item.title", :to="item.link", exact)
      v-list-item-icon
        v-icon.drawer_color--text {{ item.icon }}
      v-list-item-content
        v-list-item-title.drawer_color--text {{ item.title }}
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      items: [
        { title: "Utilisateurs", link: "/admin/users", icon: "manage_accounts" },
        { title: "Vendeurs", link: "/admin/vendeurs", icon: "mdi-qrcode" },
      ],
      right: null,
    };
  },
};
</script>
