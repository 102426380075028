<template lang="pug">
BasicCrud(
  title="",
  title-modal="un vendeur",
  item-name="vendeur",
  :service="service"
  :headers='headers',
  :default-item='defaultItem',
  max-width-dialog="600px",
  :form-is-valid="formIsValid"
  :default-search="defaultSearch"
)
  template(v-slot:alert)
    v-alert.mx-3(v-if="alert.show" :type="alert.type" )  {{alert.text}}

  template(v-slot:search="{ search,list,dataRef }" )
    v-container(fluid)
      v-form
        v-row.align-center
          v-col(cols='12' sm='6' md='4')
            v-text-field(v-model="search['vendeur.person.prenom[LIKE]']" label="Prénom" outlined hide-details clearable)
  
          v-col(cols='12' sm='6' md='4')
            v-text-field(v-model="search['vendeur.person.nom[LIKE]']" label="Nom" outlined hide-details clearable)
          
          v-col(cols='12' sm='6' md='4')
            v-select(v-model="search['vendeur.coordonnees.zone[=]']" label="Zones" :items="dataRef.zones"
            outlined clearable hide-details persistent-hint item-text="libelle" item-value="code")
        
        v-row.align-center
          v-col(cols='12' sm='12' md='12')
            v-btn.button_color--text(@click="list()" block color="button")
              v-icon search
  
  template(v-slot:item.isEnabled='{item}')
    tr
      td(v-if='item.isEnabled')
        v-icon(small)
          | check_circle
      td(v-else)
        v-icon(small)
          | disabled_by_default

  template(v-slot:item.actions.custom='{ item }')
      v-icon.ml-2(small @click='afficherQRCode(item)') mdi-qrcode
      v-icon.ml-2(small @click='afficherFormulaire(item)') mdi-eye

  template(v-slot:form="{ editedItem ,dataRef}")
    v-container
      validation-observer(ref='formObserver' v-slot='{ invalid }')
        v-card-text
          validation-provider(v-slot='{ errors }' rules='required' name="Zones")
            v-select(v-model="editedItem.zone" label="Zones *" :error-messages="errors" @change='formValidate(editedItem)' :items="dataRef.zones"
              outlined clearable hide-details persistent-hint item-text="libelle" item-value="code")
        v-card-text
          validation-provider(v-slot='{ errors }' rules='required' name="Types de point de vente")
            v-select(v-model="editedItem.pointVenteType" label="Types de point de vente *" :error-messages="errors" @change='formValidate(editedItem)' :items="dataRef.pointsVenteType"
              outlined clearable hide-details persistent-hint item-text="libelle" item-value="code")
        v-card-text
          validation-provider(v-slot='{ errors }' rules='required|max:50' name="Nom du point de vente")
            v-text-field(v-model="editedItem.pointVenteNom" label="Nom du point de vente *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:50' name="Prénom")
            v-text-field(v-model="editedItem.prenom" label="Prénom *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:50' name="Nom")
            v-text-field(v-model="editedItem.nom" label="Nom *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:50' name="Code")
            v-text-field(v-model="editedItem.code" label="Code *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:150|email' name="Email")
            v-text-field(v-model="editedItem.email" label="Email *" :error-messages="errors" @change='formValidate' outlined clearable)
          validation-provider(v-slot='{ errors }' rules='required|max:10' name="Téléphone")
            v-text-field(v-model="editedItem.tel" label="Téléphone *" :error-messages="errors" @change='formValidate' outlined clearable)
  </template>

<script>
import BasicCrud from "@/components/common/BasicCrud";
import VendeurService from "@/services/administration/vendeur.service";

import { required, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
} from "vee-validate";

import fr from "vee-validate/dist/locale/fr.json";

localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("max", max);

export default {
  components: { BasicCrud, ValidationProvider, ValidationObserver },
  data: () => ({
    service: VendeurService,
    alert: {
      show: false,
      type: "success",
      text: "",
    },
    defaultItem: {
      prenom: "",
      nom: "",
      code: "",
      email: "",
      zones: [],
    },
    defaultSearch: {},
    formIsValid: false,
    defaultPage: {
      currentPage: 1,
      items: [],
      lastPage: 1,
      nbItemsPerPage: 5,
      nbItemsTotal: 1,
    },
    headers: [
      {
        text: "Prénom",
        align: "start",
        value: "person.prenom",
        sortBy: "person.prenom",
      },
      {
        text: "Nom",
        align: "start",
        value: "person.nom",
        sortBy: "person.nom",
      },
      {
        text: "Code",
        align: "start",
        value: "person.code",
        sortBy: "person.code",
      },
      {
        text: "Email",
        align: "start",
        value: "coordonnees.email",
        sortBy: "coordonnees.email",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  methods: {
    async formValidate() {
      this.formIsValid = await this.$refs.formObserver.validate();
    },
    async afficherQRCode(item) {
      window.open("/api/qr-code/vendeurs/"+item.id, "_blank");
    },
    async afficherFormulaire(item) {
      const routeData = this.$router.resolve({
        name: "Form",
        params: { qr_code_code: item['person.code'] },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
