<template>
    <div>
        <v-row class="justify-center">
            <FormRadioGroup
                @typeVehicule="setTypeVehicule"
                :boolean="false"
                :items="data.types_vehicule"
                label=""
                emit="typeVehicule"
                :value="typeVehicule"
                :rules="typeVehiculeRules"
            />
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="modeFinancement"
                    :rules="modeFinancementRules"
                    :items="data.modes_financement"
                    item-text="libelle"
                    item-value="code"
                    label="Mode de financement*"
                    hint="Veuillez sélectionner votre mode de financement"
                ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="garantie"
                    :rules="garantieRules"
                    :items="data.garanties"
                    item-text="libelle"
                    item-value="code"
                    label="Garanties souhaitées*"
                    hint="Veuillez sélectionner la garantie souhaitée"
                    append-icon="mdi-information"
                    @click:append="
                        () => {
                            guaranteesDialog = true;
                        }
                    "
                ></v-select>

                <v-dialog v-model="guaranteesDialog" width="750">
                    <v-img
                        :src="require('@/assets/garanties_auto.png')"
                        contain
                        position="center center"
                    />
                </v-dialog>
            </v-col>
        </v-row>

        <v-row class="mt-10 justify-center">
            <v-btn
                class="max-width-100"
                color="primary"
                elevation="2"
                x-large
                outlined
                @click="openSinistresAutoDialog"
            >
                <v-icon left>mdi-car-cog</v-icon>
                Sinistralité des conducteurs sur les 36 derniers mois
            </v-btn>

            <FormSinistresAutoDialog
                :dialog="sinistresDialog"
                @open-sinistres-auto-dialog="openSinistresAutoDialog()"
                @close-sinistres-auto-dialog="closeSinistresAutoDialog()"
            />
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormRadioGroup from "@/components/FormRadioGroup";
import FormSinistresAutoDialog from "@/components/FormSinistresAutoDialog";

export default {
    name: "FormAutoSection",

    components: { FormRadioGroup, FormSinistresAutoDialog },

    data() {
        return {
            typeVehiculeValue: null,
            typeVehiculeRules: [
                (v) => !!v || "Le type du véhicule est obligatoire",
            ],
            modeFinancementRules: [
                (v) => !!v || "Un mode de financement est obligatoire",
            ],
            garantieRules: [(v) => !!v || "Une garantie est obligatoire"],
            guaranteesDialog: false,
            sinistresDialog: false,
        };
    },

    computed: {
        ...mapGetters("form", ["data", "formData"]),
        typeVehicule: {
            get() {
                return this.getModel("type_vehicule_code");
            },
        },
        modeFinancement: {
            get() {
                return this.getModel("mode_financement_code");
            },
            set(value) {
                this.setModel("mode_financement_code", value);
            },
        },
        garantie: {
            get() {
                return this.formData.demande.garantie;
            },
            set(value) {
                this.setDemande({
                    field: "garantie",
                    value: value,
                });
            },
        },
    },

    methods: {
        ...mapActions("form", ["setDemande", "setDemandeAssurAuto"]),

        getModel(field) {
            return this.formData.demande_assur_auto[field];
        },

        setModel(field, value) {
            this.setDemandeAssurAuto({
                field: field,
                value: value,
            });
        },

        setTypeVehicule(payload) {
            this.setDemandeAssurAuto({
                field: "type_vehicule_code",
                value: payload,
            });
        },

        openSinistresAutoDialog() {
            this.sinistresDialog = true;
        },

        closeSinistresAutoDialog() {
            this.sinistresDialog = false;
        },
    },
};
</script>

<style lang="scss">
button.max-width-100 {
    max-width: 100% !important;
}

button.max-width-100 > span.v-btn__content {
    width: 100%;
    white-space: normal;
}
</style>