<template>
    <div>
        <v-file-input
            v-model="identite"
            :rules="identiteRules && fileRules"
            label="Pièce d'identité*"
            hint="(CNI ou Passeport)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-model="permisRecto1"
            :rules="permis1Rules && fileRules"
            label="Permis de conduire (recto)*"
            hint="(pour le conducteur habituel)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-model="permisVerso1"
            :rules="permis1Rules && fileRules"
            label="Permis de conduire (verso)*"
            hint="(pour le conducteur habituel)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-if="secondConducteur"
            v-model="permisRecto2"
            :rules="permis2Rules && fileRules"
            label="Permis de conduire (recto)*"
            hint="(pour le second conducteur)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-if="secondConducteur"
            v-model="permisVerso2"
            :rules="permis2Rules && fileRules"
            label="Permis de conduire (verso)*"
            hint="(pour le second conducteur)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-model="vehicule"
            :rules="vehiculeRules && fileRules"
            label="Identité du véhicule*"
            hint="(Bon de commande ou Carte grise (recto) ou Fiche technique)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-switch
            @change="toggleCGVerso"
            v-model="cgVerso"
            inset
            label="J'ai fourni une Carte grise"
        ></v-switch>

        <v-file-input
            v-if="cgVerso"
            v-model="carteGriseVerso"
            :rules="vehiculeRules && fileRules"
            label="Carte grise (verso)*"
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-model="controleTechnique"
            :rule="fileRules"
            label="Contrôle technique"
            hint="(si véhicule plus de 4 ans)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-model="releveInfo1"
            :rule="fileRules"
            label="Relevé d'information"
            hint="(pour le conducteur habituel)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>

        <v-file-input
            v-if="secondConducteur"
            :rule="fileRules"
            v-model="releveInfo2"
            label="Relevé d'information"
            hint="(pour le second conducteur)"
            persistent-hint
            show-size
            truncate-length="25"
            accept=".jpg, .jpeg, .png, .pdf"
        ></v-file-input>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "FormAutoDocumentsSection",

    props: {
        secondConducteur: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            cgVerso: false,
            identiteRules: [
                (v) => !!v || "Une pièce d'identité valide est obligatoire",
            ],
            permis1Rules: [
                (v) =>
                    !!v ||
                    "Le permis de conduire du conducteur habituel est obligatoire",
            ],
            permis2Rules: [
                (v) =>
                    !!v ||
                    "Le permis de conduire du second conducteur est obligatoire",
            ],
            vehiculeRules: [
                (v) =>
                    !!v ||
                    "Un justificatif d'identité du véhicule est obligatoire",
            ],
            fileRules: [
                (v) =>
                    !v ||
                    v.size <= 8 * 1024 * 1024 ||
                    "La taille du fichier ne doit pas dépasser 8 Mo",
            ],
            guaranteesDialog: false,
        };
    },

    computed: {
        ...mapGetters("form", ["formData"]),
        permisRecto1: {
            get() {
                return this.getModel("justificatif_permis_recto");
            },
            set(value) {
                this.setModel("justificatif_permis_recto", value);
            },
        },
        permisVerso1: {
            get() {
                return this.getModel("justificatif_permis_verso");
            },
            set(value) {
                this.setModel("justificatif_permis_verso", value);
            },
        },
        permisRecto2: {
            get() {
                return this.getModel("justificatif_permis_recto", true);
            },
            set(value) {
                this.setModel("justificatif_permis_recto", value, true);
            },
        },
        permisVerso2: {
            get() {
                return this.getModel("justificatif_permis_verso", true);
            },
            set(value) {
                this.setModel("justificatif_permis_verso", value, true);
            },
        },
        vehicule: {
            get() {
                return this.getModel("justificatif_vehicule");
            },
            set(value) {
                this.setModel("justificatif_vehicule", value);
            },
        },
        carteGriseVerso: {
            get() {
                return this.getModel("justificatif_carte_grise_verso");
            },
            set(value) {
                this.setModel("justificatif_carte_grise_verso", value);
            },
        },
        controleTechnique: {
            get() {
                return this.getModel("justificatif_controle_technique");
            },
            set(value) {
                this.setModel("justificatif_controle_technique", value);
            },
        },
        releveInfo1: {
            get() {
                return this.getModel("justificatif_releve_info");
            },
            set(value) {
                this.setModel("justificatif_releve_info", value);
            },
        },
        releveInfo2: {
            get() {
                return this.getModel("justificatif_releve_info", true);
            },
            set(value) {
                this.setModel("justificatif_releve_info", value, true);
            },
        },
        identite: {
            get() {
                return this.formData.demande_coordonnees.justificatif_identite;
            },
            set(value) {
                this.setDemandeCoordonnees({
                    field: "justificatif_identite",
                    value: value,
                });
            },
        },
    },

    methods: {
        ...mapActions("form", [
            "setDemandeCoordonnees",
            "setDemandeAssurAuto",
            "setDemandeAssurAutoRecursive",
        ]),
        getModel(field, secondConducteur) {
            if (secondConducteur) {
                return this.formData.demande_assur_auto.second_conducteur[
                    field
                ];
            } else {
                return this.formData.demande_assur_auto[field];
            }
        },
        setModel(field, value, secondConducteur) {
            if (secondConducteur) {
                this.setDemandeAssurAutoRecursive({
                    field: field,
                    value: value,
                });
            } else {
                this.setDemandeAssurAuto({
                    field: field,
                    value: value,
                });
            }
        },
        toggleCGVerso(value) {
            if (!value) {
                this.setModel("justificatif_carte_grise_verso", null);
            }
        },
    },
};
</script>
