<template lang="pug">
v-container(fluid)
  slot(name="alert")
  v-dialog(v-model='dialog' :max-width='maxWidthDialog' :persistent="true")
    template(v-slot:activator='{ on, attrs }')
      slot(name="btn")
      v-btn.mb-4(color='primary' v-on='on' @click="newItem" v-bind='attrs' absolute right fab  v-if="!isReadOnly")
        v-icon add
    v-card
      v-card-title
        span.text-h5 {{ formTitle }}
      slot(name="form" v-bind:editedItem="editedItem" :dataRef="dataRef")
      v-card-actions
        v-spacer
        v-btn(color='primary' text @click='close')
          | Annuler
        v-btn(color='primary' text @click='saveAndAdd' :disabled='!formIsValid' v-if="canSaveAndAdd")
          | Sauvegarder et Ajouter
        v-btn(color='primary' text @click='save' :disabled='!formIsValid')
          | Sauvegarder
  v-dialog(v-model='dialogDelete' max-width='500px')
    v-card
      v-card-title.text-h6 {{removeText ? removeText : 'Êtes-vous sûr de bien vouloir supprimer ?'}}
      v-card-actions
        v-spacer
        v-btn(color='primary' text @click='closeDelete') Annuler
        v-btn(color='primary' text @click='deleteItemConfirm') OK
  v-card.ma-3
    v-card-title
      v-icon.primary--text(large) {{icon}}
      span.text-h5.ml-3 {{title}}
    v-card-text
      v-layout.align-center.filtre(row wrap)
        slot(name="search" :search="search" :list="listItems" :exportItems="exportItems" :dataRef="dataRef" )
    v-data-table.elevation-1.datatable(
      @click:row="onRowClick"
      :headers='headers'
      :items='page.items'
      :loading="loading"
      loading-text="Chargement en cours..."
      multi-sort
      :options.sync="options"
      :server-items-length="page.nbItemsTotal"
      :footer-props="{showFirstLastPage: true, itemsPerPageOptions:[5,10,20,50,100]}"
      :expanded.sync="expanded"
      :single-expand="true"
      :show-expand="showExpand"
      @dblclick:row="editItemOnDblClk"
    )
      template(v-for='header in headers' v-slot:[`item.${header.value}`]='{ item }')
        slot(:name='[`item.${header.value}`]' :item='item')
          v-tooltip(bottom)
            template(v-slot:activator='{ on }')
              tr
                td.truncate(v-on="on" v-text="item[header.value]" )
            span(v-text="item[header.value]")
      template(v-slot:[`item.actions`]='{ item }')
        slot(:name='[`item.actions`]' :item='item' :deleteItem='deleteItem' v-if="!isReadOnly"  )
          v-icon.mr-2(small @click="editItem(item)") edit
          v-icon(small @click='deleteItem(item)') delete
        slot(:name='[`item.actions.custom`]' :item='item' :list='listItems' :dataRef="dataRef")
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan='headers.length')
          slot(name='expanded' :item='item')
</template>
<style scoped>

.filtre .v-input {
  padding: 0;
}

.truncate {
  max-width: 400px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
<script>

export default {
  props: [
    //Title
    'icon',
    'title',
    'titleModal',
    'fullTitleModalAdd',
    'fullTitleModalEdit',
    //Datatable
    'defaultPage',
    'service',
    'headers',
    'removeText',
    'isReadOnly',
    //Form
    'defaultItem',
    'formIsValid',
    'maxWidthDialog',
    //operation override
    'create',
    'update',
    'remove',
    'list',
    'getById',
    'listOptions',
    'dataRefOptions',
    'change',
    'canSaveAndAdd',
    'showExpand',
    'onRowExpand',
    'defaultSearch',
    'isCancellable'
  ],
  data: () => ({
    search: {},
    dialog: false,
    dialogDelete: false,
    editedItem: {},
    editedId: -1,
    options: {},
    loading: true,
    dataRef: {},
    page: {
      currentPage: 1,
      items: [],
      lastPage: 1,
      nbItemsPerPage: 10,
      nbItemsTotal: 1,
      sortOption: {}
    },
    expanded: [],
    singleExpand: false,
    cancellable: true
  }),
  created() {
    this.page = this.$props.defaultPage ? this.$props.defaultPage : this.page;
    this.search = this.$props.defaultSearch ? this.$props.defaultSearch : this.search;
    this.editedItem = this.$props.defaultItem ? Object.assign({}, this.$props.defaultItem) : {};
    this.cancellable = typeof this.$props.isCancellable !== 'undefined' ? this.$props.isCancellable : true;
  },
  mounted() {
    this.getReferentials();
  },
  computed: {
    formTitle() {
      let title = this.editedId === -1 ? 'Ajouter ' : 'Modifier ';
      title += ' ' + this.$props.titleModal ? this.$props.titleModal : '';
      if (this.$props.fullTitleModalAdd && this.editedId === -1) {
        return this.$props.fullTitleModalAdd;
      }
      if (this.$props.fullTitleModalEdit && this.editedId !== -1) {
        return this.$props.fullTitleModalEdit;
      }
      return title;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options;
        const sortOption = sortDesc.reduce((sortOption, isDesc, index) => {
          let headerToOrder = this.headers.find(header => header.value === sortBy[index]);
          if (headerToOrder) {
            sortOption[headerToOrder.sortBy] = isDesc ? 'DESC' : 'ASC';
          }
          return sortOption;
        }, {});
        this.page.sortOption = sortOption;
        this.page.currentPage = page;
        this.page.nbItemsPerPage = itemsPerPage;
        this.listItems(page, itemsPerPage, sortOption);
      },
      deep: true,
    },
  },
  methods: {
    onRowClick(item, event) {
      if (this.$props.showExpand) {
        if (event.isExpanded) {
          const index = this.expanded.findIndex(i => i === item);
          this.expanded.splice(index, 1)
        } else {
          this.expanded = [];
          this.expanded.push(item);
        }
      }
    },
    async newItem() {
      this.editedItem = Object.assign({}, this.$props.defaultItem);
      this.$emit('new', this.editedItem);
    },
    async editItemOnDblClk(event, {item}) {
      if (!this.isReadOnly) {
        await this.editItem(item);
      }
    },
    async editItem(item) {
      this.editedId = item.id;
      if (this.$props.getById) {
        const res = await this.$props.getById(item.id);
        if (res.success) {
          this.editedItem = res.data;
        } else {
          console.error(res.message);
        }
      } else if (this.service.getById) {
        const res = await this.service.getById(item.id);
        if (res.success) {
          this.editedItem = res.data;
        } else {
          console.error(res.message);
        }
      } else {
        this.editedItem = Object.assign({}, item);
      }
      this.dialog = true;
      this.$emit('edit', this.editedItem);
    },
    deleteItem(item) {
      this.editedId = item.id;
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const removeFunction = this.$props.remove ? this.$props.remove : this.removeItem;
      removeFunction(this.editedId, this.editedItem);
      this.closeDelete();
      this.$emit('delete', this.editedItem);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedId = -1;
      });
    },
    async saveAndAdd() {
      await this.save();
      this.dialog = true;
      await this.newItem();
    },
    async save() {
      if (this.editedId !== -1) {
        const updateFunction = this.$props.update ? this.$props.update : this.updateItem;
        await updateFunction(this.editedId, this.editedItem);
      } else {
        const createFunction = this.$props.create ? this.$props.create : this.createItem;
        await createFunction(this.editedItem);
      }
      this.close();
    },
    async listItems(currentPage, itemsPerPage, sortOption) {
      this.loading = true;
      for (const key in this.search) {
        if (this.search[key] === "" || this.search[key] === null) {
          delete this.search[key];
        }
      }
      currentPage = currentPage ? currentPage : this.page.currentPage;
      itemsPerPage = itemsPerPage ? itemsPerPage : this.page.nbItemsPerPage;
      sortOption = sortOption ? sortOption : this.page.sortOption;
      const res = await this.$props.service.list(
          currentPage,
          itemsPerPage,
          sortOption,
          this.search,
          this.$props.listOptions ?? null
      );
      if (res.success) {
        this.page = res.data;
        this.page.sortOption = sortOption;
      } else {
        console.error(res.message);
      }
      this.loading = false;
      this.$emit('list', this.page);
    },
    async exportItems(sortOption) {
      for (const key in this.search) {
        if (this.search[key] === "" || this.search[key] === null) {
          delete this.search[key];
        }
      }
      sortOption = sortOption ? sortOption : this.page.sortOption;
      const res = await this.$props.service.export(
          sortOption,
          this.search,
          this.$props.listOptions ?? null
      );
      if (res.success) {
        this.exportToCSV(res.data);
        // let csv = "client,createdAt,demandeAuto_client_nom,demandeAuto_client_prenom,demandeAuto_garantie,demandeAuto_id,demandeMRH_client_nom,demandeMRH_client_prenom,demandeMRH_garantie,demandeMRH_id,garantie,id,infoContrat.numeroContrat,infoContrat.tarif,nature,pointVente_nom,statutActuel,vendeur,vendeur_nom,vendeur_prenom"
        // csv += "\n";
        // res.data.forEach((demande) => {
        //   csv += Object.keys(demande).map(function(index){return demande[index]}).join(",");
        //   csv += "\n";
        // });
    
        // const anchor = document.createElement('a');
        // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        // anchor.target = '_blank';
        // anchor.download = 'export_demandes_' + Date.now() + '.csv';
        // anchor.click();
      } else {
        console.error(res.message);
      }
    },
    async createItem(item) {
      await this.service.create(item);
      await this.listItems();
    },
    async removeItem(id) {
      await this.service.remove(id);
      await this.listItems();
    },
    async updateItem(id, item) {
      await this.service.update(id, item);
      await this.listItems();
    },
    async getReferentials() {
      if (this.service.getReferentials) {
        const dataRefOptions = this.$props.dataRefOptions ? Object.assign({}, this.$props.dataRefOptions) : null;
        const res = await this.service.getReferentials(dataRefOptions);
        if (res.success) {
          this.dataRef = res.data;
        } else {
          console.error(res.message);
        }
      }

    },
    exportToCSV(data) {
      // Créer le contenu du fichier CSV
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "sep=,\r\n"; // Ajouter la séquence de caractères pour indiquer le séparateur de champ

      // Créer l'en-tête du fichier CSV
      const csvHeader = Object.keys(data[0]).join(",");
      csvContent += csvHeader + "\r\n";

      // Générer les lignes de données
      data.forEach(item => {
        let dataLine = "";
        for (let key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            dataLine += item[key] + ",";
          }
        }
        csvContent += dataLine + "\r\n";
      });

      // Télécharger le fichier CSV
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", 'export_demandes_' + Date.now() + '.csv');
      document.body.appendChild(link);
      link.click();
    }
  },
};
</script>
