import axios from "axios";

const API_URL = "/api";
const API_FORM_URL = API_URL + "/forms";
const API_ADMIN_URL = API_URL + "/admin/demandes";

class DemandeService {
  async afficherForm(qrCodeCode) {
    const res = await axios({
      method: "get",
      url: API_FORM_URL + "/" + qrCodeCode,
    });
    return res.data;
  }

  async getReferentials() {
    const res = await axios({
      method: "get",
      url: API_ADMIN_URL + "/referentials",
    });
    return res.data;
  }

  async getById(idDemande) {
    const res = await axios({
      method: "get",
      url: API_ADMIN_URL + "/" + idDemande,
    });
    return res.data;
  }

  async list(page, nbItemsPerPage, sortOption, search) {
    const res = await axios({
      method: "get",
      url: API_ADMIN_URL,
      params: { page, nbItemsPerPage, sortOption, search },
    });
    return res.data;
  }

  async export(page, nbItemsPerPage, sortOption, search) {
    const res = await axios({
      method: "get",
      url: API_ADMIN_URL + '/export',
      params: { page, nbItemsPerPage, sortOption, search },
    });
    return res.data;
  }

  async modifierStatut(demandeId, data) {
    let res = await axios({
      method: "put",
      url: API_ADMIN_URL + "/statuts/" + demandeId,
      data: data,
    });
    return res.data;
  }

  async modifier(demandeId, data) {
    let res = await axios({
      method: "put",
      url: API_ADMIN_URL + "/" + demandeId,
      data: data,
    });
    return res.data;
  }

  async telecharger(justificatif) {
    let res = await axios({
      method: "get",
      url: API_ADMIN_URL + "/justificatifs/" + justificatif,
      responseType: "blob", // important
    });
    return res.data;
  }
}

export default new DemandeService();
