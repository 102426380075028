<template lang="pug">
div
  v-card
    v-card-title.primary.white--text Consulter et éditer mes informations
    v-card-subtitle.primary.white--text Voir et éventuellement modifier mes informations personnelles
    validation-observer(ref='userObserver' v-slot='{ invalid }')
      v-card-text
        input(type='hidden' name='user.id')
        validation-provider(v-slot='{ errors }' vid='user.firstName' name='prénom' rules='required|max:35')
          v-text-field(v-model='user.firstName' :counter='35' :error-messages='errors' label='Prénom' required='')
        validation-provider(v-slot='{ errors }' vid='user.lastName' name='nom' rules='required|max:35')
          v-text-field(v-model='user.lastName' :counter='35' :error-messages='errors' label='Nom' required='')
        validation-provider(v-slot='{ errors }' vid='user.email' name='email' rules='required|max:50|email')
          v-text-field(v-model='user.email' :error-messages='errors' label='Email' required='')
      v-card-actions.text-right
        v-btn.button_color--text(:loading='loading' color='button' @click='updateMyProfile' :disabled='invalid') enregistrer
  SnackBar(v-bind:show='showSnackBar' v-bind:message='message')
</template>

<script>
import { required, email, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
} from "vee-validate";

import fr from "vee-validate/dist/locale/fr.json";

localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("email", email);
extend("max", max);

import UserService from "@/services/account.service";
import SnackBar from "@/components/common/SnackBar";

export default {
  name: "ProfileForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar,
  },
  data: () => ({
    showSnackBar: false,
    message: {
      type: "error",
      message: "test",
    },

    loading: false,
    user: {},
  }),
  created() {
    this.getMyProfile();
  },
  methods: {
    async getMyProfile() {
      try {
        this.loading = false;
        const response = await UserService.getMyProfile();
        this.message = {
          type: response.success ? "success" : "error",
          message: response.message,
        };
        this.user = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateMyProfile() {
      try {
        this.loading = true;
        const response = await UserService.updateMyProfile(this.user);
        this.message = {
          type: response.success ? "success" : "error",
          message: response.message,
        };
        this.loading = false;
      } catch (error) {
        this.message = {
          type: "error",
          message: error.response.data.message,
        };
        console.error(error);
      } finally {
        this.loading = false;
        this.showSnackBar = true;
      }
    },
  },
};
</script>
