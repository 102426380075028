<template>
    <v-container fluid class="mb">
        <v-row align="center" justify="center">
            <v-btn-toggle
                :value="value"
                group
                @change="setSelectedGender"
            >
                <v-col
                    class="mx-10 mt-10"
                    v-for="gender in orderBy(genders, 'order')"
                    :key="gender.order"
                >
                    <v-row align="center" justify="center">
                        <v-btn
                            rounded
                            text
                            icon
                            color="primary"
                            :value="gender.code"
                        >
                            <v-icon x-large>{{ gender.icon }}</v-icon>
                        </v-btn>
                    </v-row>
                    <v-row>
                        <span
                            :class="
                                value == gender.code
                                    ? 'text-decoration-underline'
                                    : ''
                            "
                            >{{ gender.name }}</span
                        >
                    </v-row>
                </v-col>
            </v-btn-toggle>
        </v-row>

        <v-row class="input-gender" lign="center" justify="center">
            <v-col cols="12" sm="6" md="3">
                <v-input :value="value" dense :rules="rules"></v-input>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
    name: 'FormGenderSelect',

    props: {
        genders: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            default: null,
        },
        rules: {
            type: Array,
            required: true,
        },
    },

    methods: {
        setSelectedGender(payload) {
            this.$emit('setCivilite', payload);
        },
    },

    mixins: [Vue2Filters.mixin],
};
</script>

<style lang="scss">
.input-gender .v-messages__wrapper {
    text-align: center !important;
}
</style>
