<template lang="pug">
BasicCrud(
    title="",
    title-modal="une demande",
    item-name="demande",
    :service="service",
    :headers="headers",
    max-width-dialog="600px",
    :default-search="defaultSearch",
    :isReadOnly="true"
)
    template(v-slot:alert)
        v-alert.mx-3(v-if="alert.show", :type="alert.type") {{ alert.text }}

    template(v-slot:search="{ search, list, exportItems, dataRef }")
        v-container(fluid)
            v-form
                v-row.align-center
                    v-col(cols="12", sm="6", md="3")
                        v-text-field(
                            v-model="search['demande.infoContrat.numeroContrat[LIKE]']",
                            label="N° de contrat",
                            outlined,
                            hide-details,
                            clearable
                        )

                    v-col(cols="12", sm="6", md="3")
                        v-select(
                            v-model="search['demande.nature[IN]']",
                            label="Nature",
                            :items="dataRef.natures",
                            outlined,
                            clearable,
                            hide-details,
                            persistent-hint,
                            item-text="libelle",
                            item-value="code",
                            multiple
                        )

                    v-col(cols="12", sm="6", md="3")
                        v-select(
                            v-model="search['demande.statutActuel[IN]']",
                            label="Statut",
                            :items="dataRef.statuts",
                            outlined,
                            clearable,
                            hide-details,
                            persistent-hint,
                            item-text="libelle",
                            item-value="code",
                            multiple
                        )

                    v-col(cols="12", sm="6", md="3")
                        v-select(
                            v-model="search['demande_garantie_code[IN]']",
                            label="Garantie",
                            :items="dataRef.allGaranties",
                            outlined,
                            clearable,
                            hide-details,
                            persistent-hint,
                            item-text="libelle",
                            item-value="code",
                            multiple
                        )

                v-row.align-center
                    v-col(cols="12", sm="6", md="4")
                        v-text-field(
                            v-model="search['vendeur.pointVente.nom[LIKE]']",
                            label="Nom du point de vente",
                            outlined,
                            hide-details,
                            clearable
                        )

                    v-col(cols="12", sm="6", md="4")
                        v-text-field(
                            v-model="search['vendeur.person.prenom[LIKE]']",
                            label="Prénom du vendeur",
                            outlined,
                            hide-details,
                            clearable
                        )

                    v-col(cols="12", sm="6", md="4")
                        v-text-field(
                            v-model="search['vendeur.person.nom[LIKE]']",
                            label="Nom du vendeur",
                            outlined,
                            hide-details,
                            clearable
                        )

                v-row.align-center
                    v-col(cols="12", sm="6", md="6")
                        v-text-field(
                            v-model="search['demande_client_prenom[LIKE]']",
                            label="Prénom du client",
                            outlined,
                            hide-details,
                            clearable
                        )

                    v-col(cols="12", sm="6", md="6")
                        v-text-field(
                            v-model="search['demande_client_nom[LIKE]']",
                            label="Nom du client",
                            outlined,
                            hide-details,
                            clearable
                        )

                v-row.align-center
                    v-col(cols="12", sm="6", md="6")
                        v-text-field(
                            v-model="search['demande.createdAt[>=]']",
                            label="Date de début de réception",
                            outlined,
                            hide-details,
                            clearable,
                            type="date"
                        )

                    v-col(cols="12", sm="6", md="6")
                        v-text-field(
                            v-model="search['demande.createdAt[<=]']",
                            label="Date de fin de réception",
                            outlined,
                            hide-details,
                            clearable,
                            type="date"
                        )

                v-row.align-center(v-if="$store.state.auth.data.user.isAdmin")
                    v-col(cols="12", sm="12", md="12")
                        v-btn.button_color--text(
                            @click="exportItems()",
                            block,
                            color="button",
                            title="Exporter les demandes"
                        )
                            v-icon mdi-file-excel

                v-row.align-center
                    v-col(cols="12", sm="12", md="12")
                        v-btn.button_color--text(
                            @click="list()",
                            block,
                            color="button",
                            title="Rechercher les demandes"
                        )
                            v-icon search

    template(v-slot:item.actions.custom="{ item }")
        v-icon.ml-2(small, @click="afficherDemande(item)") mdi-eye
</template>

<script>
import BasicCrud from "@/components/common/BasicCrud";
import DemandeService from "@/services/demande.service";

import { required, max } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
    localize,
} from "vee-validate";

import fr from "vee-validate/dist/locale/fr.json";

localize("fr", fr);
setInteractionMode("eager");
extend("required", required);
extend("max", max);

export default {
    name: "Demande",
    components: { BasicCrud, ValidationProvider, ValidationObserver },
    data: () => ({
        service: DemandeService,
        alert: {
            show: false,
            type: "success",
            text: "",
        },
        defaultSearch: { "demande.statutActuel[IN]": ["DEMANDE_DEVIS"] },
        defaultPage: {
            currentPage: 1,
            items: [],
            lastPage: 1,
            nbItemsPerPage: 5,
            nbItemsTotal: 1,
            sortOption: { "demande.createdAt": "ASC" },
        },
        headers: [
            {
                text: "N° de contrat",
                align: "start",
                value: "infoContrat.numeroContrat",
                sortBy: "demande.infoContrat.numeroContrat",
            },
            {
                text: "Nature du projet",
                align: "start",
                value: "nature",
                sortBy: "demande.nature",
            },
            {
                text: "Garantie",
                align: "start",
                value: "demande_garantie_code",
                sortBy: "demande_garantie_code",
            },
            {
                text: "Vendeur",
                align: "start",
                value: "vendeur",
                sortBy: "vendeur.person.nom",
            },
            {
                text: "Client",
                align: "start",
                value: "client",
                sortBy: "demande_client_nom",
            },
            {
                text: "Date de réception",
                align: "start",
                value: "createdAt",
                sortBy: "demande.createdAt",
            },
            {
                text: "Statut",
                align: "start",
                value: "statutActuel",
                sortBy: "demande.statutActuel",
            },
            {
                text: "Date du statut",
                align: "start",
                value: "dateDernierStatut",
                sortBy: "dateDernierStatut",
            },
            {
                text: "Tarif",
                align: "start",
                value: "infoContrat.tarif",
                sortBy: "demande.infoContrat.tarif",
            },
            { text: "Actions", value: "actions", sortable: false },
        ],
        exportLoading: false,
    }),
    methods: {
        afficherDemande(item) {
            switch (item.nature) {
                case "AUTO":
                    this.$router.push(`/demande/${item.demandeAuto_id}/auto`);
                    break;

                case "MRH":
                    this.$router.push(`/demande/${item.demandeMRH_id}/mrh`);
                    break;

                default:
                    this.$router.push("/");
            }
        },
    },
};
</script>
