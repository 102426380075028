<template>
    <div>
        <v-row>
            <v-col class="mt-10" cols="12" sm="12" md="6">
                <v-slider
                    v-model="bonusMalus"
                    label="Bonus"
                    step="0.01"
                    min="0.50"
                    max="1.25"
                    thumb-label="always"
                    ticks
                ></v-slider>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "FormAutoSection",

    props: {
        recursive: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters("form", ["formData"]),
        bonusMalus: {
            get() {
                return this.getModel("bonus_malus");
            },
            set(value) {
                this.setModel("bonus_malus", value);
            },
        },
    },

    methods: {
        ...mapActions("form", [
            "setDemandeAssurAuto",
            "setDemandeAssurAutoRecursive",
        ]),
        getModel(field) {
            if (this.recursive) {
                return this.formData.demande_assur_auto.second_conducteur[
                    field
                ];
            } else {
                return this.formData.demande_assur_auto[field];
            }
        },
        setModel(field, value) {
            if (this.recursive) {
                this.setDemandeAssurAutoRecursive({
                    field: field,
                    value: value,
                });
            } else {
                this.setDemandeAssurAuto({
                    field: field,
                    value: value,
                });
            }
        },
    },
};
</script>
