<template lang="pug">
div
  v-app-bar.app_bar_top(app fixed clipped-left height='80' elevation='3')
    v-app-bar-nav-icon(@click.stop="$emit('toggle-drawer')")
      v-icon(small) menu
    v-avatar(tile)
      router-link(to='/')
        v-img(lazy-src max-height='40' max-width='40' v-if='darkMode === true' :src='require("@/assets/images/logo_transparent.png")')
        v-img(lazy-src max-height='40' max-width='40' v-else :src='require("@/assets/images/logo_white.png")')
    v-toolbar-title.mx-5 {{ $route.meta.title }}
    v-spacer
    AccountMenu(v-on:childToParent="onChildClick")
</template>

<script>
import AccountMenu from "@/components/layout/common/AccountMenu";

export default {
  name: "AppBar",
  components: {
    AccountMenu,
  },
  data: () => ({
    darkMode: false,
  }),
  methods: {
    // Triggered when `childToParent` event is emitted by the child.
    onChildClick(value) {
      this.darkMode = value;
    },
  },
};
</script>
