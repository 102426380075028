<template>
    <FormLayout :footer="!!this.data">
        <template #form-content>
            <FormLoading :loading="loading" />

            <FormAuto v-if="!loading && formType == 'AUTO'" />

            <FormMRH v-if="!loading && formType == 'MRH'" />

            <FormAlertMessage
                @open-alert="openAlert"
                @close-alert="closeAlert"
                v-bind="alert"
            />
        </template>
    </FormLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DemandeService from "@/services/demande.service";

import FormLayout from '@/components/layout/FormLayout';
import FormLoading from '@/components/FormLoading.vue';
import FormAuto from '@/components/FormAuto.vue';
import FormMRH from '@/components/FormMRH.vue';
import FormAlertMessage from '@/components/FormAlertMessage.vue';

export default {
    name: 'FormView',

    components: {
        FormLayout,
        FormLoading,
        FormAuto,
        FormMRH,
        FormAlertMessage,
    },

    computed: {
        ...mapGetters('form', ['data']),
        formType() {
            return this.data ? this.data.type_demande : null;
        },
    },

    data() {
        return {
            loading: true,
            alert: {
                show: false,
                type: 'error',
                message: '',
                component: 'bottom-sheet',
            },
        };
    },

    mounted() {
        this.afficherForm();
    },

    methods: {
        ...mapActions('form', ['setData', 'setDemande', 'resetFormData']),
        async afficherForm() {
            await DemandeService.afficherForm(
                this.$route.params.qr_code_code
            )
                .then((response) => {
                    this.setData({ data: response.data });
                })
                .catch((response) => {
                    let message = response.response.data.message
                        ? response.response.data.message
                        : response.message;

                    this.showErrorMessage(message);
                });

            this.resetFormData();

            this.setDemande({
                field: 'qr_code_code',
                value: this.$route.params.qr_code_code,
            });

            this.loading = false;
        },
        showErrorMessage(message) {
            this.alert.message = message;
            this.openAlert();
        },
        openAlert() {
            this.alert.show = true;
        },
        closeAlert() {
            this.alert.show = false;
        },
    },
};
</script>
