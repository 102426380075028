<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <FormRadioGroup
                    @typeResidence="setTypeResidence"
                    :boolean="false"
                    :items="data.types_residence"
                    label="Je souhaite assurer*"
                    emit="typeResidence"
                    :value="typeResidence"
                    :rules="typeResidenceRules"
                />
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <FormRadioGroup
                    @typeBien="setTypeBien"
                    :boolean="false"
                    :items="data.types_bien"
                    label="Type de bien*"
                    emit="typeBien"
                    :value="typeBien"
                    :rules="typeBienRules"
                />
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <FormRadioGroup
                    @typePersonne="setTypePersonne"
                    :boolean="false"
                    :items="data.types_personne"
                    label="Je suis*"
                    emit="typePersonne"
                    :value="typePersonne"
                    :rules="typePersonneRules"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="nbPieces"
                    :rules="nbPiecesRules"
                    maxlength="3"
                    counter="3"
                    hint="Veuillez renseigner le nombre de pièces de votre bien (chambres + séjour)"
                    label="Nombre de pièces*"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="superficie"
                    :rules="superficieRules"
                    maxlength="6"
                    counter="6"
                    hint="Veuillez renseigner la superficie de votre bien en m²"
                    label="Superficie*"
                    suffix="m²"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="superficieSup"
                    :rules="superficieSupRules"
                    maxlength="6"
                    counter="6"
                    hint="Veuillez renseigner la superficie de vos espaces supplémentaires en m²"
                    label="Superficie véranda, terrasse, varangue..."
                    suffix="m²"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <v-text-field
                    v-model="superficieDependances"
                    :rules="superficieDependancesRules"
                    maxlength="6"
                    counter="6"
                    hint="Veuillez renseigner la superficie de vos dépendances en m²"
                    label="Superficie des dépendances*"
                    suffix="m²"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <!-- <v-col cols="12" sm="6" md="4">
                <v-text-field
                    v-model="valeur"
                    :rules="valeurRules"
                    maxlength="10"
                    counter="10"
                    hint="Veuillez renseigner la valeur de votre bien en €"
                    label="Valeur du bien*"
                    suffix="€"
                ></v-text-field>
            </v-col> -->

            <v-col cols="12" sm="6" md="6">
                <v-text-field
                    v-model="valeurContenu"
                    :rules="valeurContenuRules"
                    maxlength="10"
                    counter="10"
                    hint="Veuillez renseigner la valeur de votre contenu en €"
                    label="Valeur du contenu*"
                    suffix="€"
                    append-icon="mdi-information"
                    @click:append="openDefinitionValeurContenuDialog"
                ></v-text-field>

                <FormDefinitionValeurContenuDialog
                    :dialog="definitionValeurContenuDialog"
                    @open-definition-valeur-contenu-dialog="
                        openDefinitionValeurContenuDialog()
                    "
                    @close-definition-valeur-contenu-dialog="
                        closeDefinitionValeurContenuDialog()
                    "
                />
            </v-col>

            <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="distanceMer"
                    :rules="distanceMerRules"
                    :items="data.distances_mer"
                    item-text="libelle"
                    item-value="code"
                    label="Distance à la mer*"
                    hint="Veuillez sélectionner la distance à la mer"
                ></v-select>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="6">
                <FormRadioGroup
                    @installationsExterieures="setInstallationsExterieures"
                    :boolean="true"
                    label="Installations extérieures ?*"
                    emit="installationsExterieures"
                    :value="installationsExterieures"
                    :rules="installationsExterieuresRules"
                    :information-dialog="true"
                    @setInformationDialog="setInformationDialog"
                />

                <v-dialog
                    v-model="definitionInstallationsExterieuresDialog"
                    width="750"
                >
                    <v-img
                        :src="
                            require('@/assets/definition_installations_exterieures.jpg')
                        "
                        contain
                        position="center center"
                    />
                </v-dialog>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="3">
                <FormRadioGroup
                    @dommagesElectriques="setDommagesElectriques"
                    :boolean="true"
                    label="Dommages électriques ?*"
                    emit="dommagesElectriques"
                    :value="dommagesElectriques"
                    :rules="dommagesElectriquesRules"
                />
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <FormRadioGroup
                    @individuelleScolaire="setIndividuelleScolaire"
                    :boolean="true"
                    label="Individuelle scolaire ?*"
                    emit="individuelleScolaire"
                    :value="individuelleScolaire"
                    :rules="individuelleScolaireRules"
                />
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <FormRadioGroup
                    @piscine="setPiscine"
                    :boolean="true"
                    label="Piscine ?*"
                    emit="piscine"
                    :value="piscine"
                    :rules="piscineRules"
                />
            </v-col>

            <v-col cols="12" sm="6" md="3">
                <FormRadioGroup
                    @energiesRenouvelables="setEnergiesRenouvelables"
                    :boolean="true"
                    label="Énergies renouvelables ?*"
                    emit="energiesRenouvelables"
                    :value="energiesRenouvelables"
                    :rules="energiesRenouvelablesRules"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="garantie"
                    :rules="garantieRules"
                    :items="data.garanties"
                    item-text="libelle"
                    item-value="code"
                    label="Garanties souhaitées*"
                    hint="Veuillez sélectionner la garantie souhaitée"
                    append-icon="mdi-information"
                    @click:append="
                        () => {
                            guaranteesDialog = true;
                        }
                    "
                ></v-select>

                <v-dialog v-model="guaranteesDialog" width="750">
                    <v-img
                        :src="require('@/assets/garanties_mrh.png')"
                        contain
                        position="center center"
                    />
                </v-dialog>
            </v-col>
        </v-row>

        <v-row class="mt-10 justify-center">
            <v-btn
                class="max-width-100"
                color="primary"
                elevation="2"
                x-large
                outlined
                @click="openSinistresMRHDialog"
            >
                <v-icon left>mdi-home-flood</v-icon>
                Sinistres au cours des 36 derniers mois
            </v-btn>
        </v-row>

        <FormSinistresMRHDialog
            :dialog="sinistresDialog"
            @open-sinistres-mrh-dialog="openSinistresMRHDialog()"
            @close-sinistres-mrh-dialog="closeSinistresMRHDialog()"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormRadioGroup from "@/components/FormRadioGroup";
import FormSinistresMRHDialog from "@/components/FormSinistresMRHDialog";
import FormDefinitionValeurContenuDialog from "@/components/FormDefinitionValeurContenuDialog";

export default {
    name: "FormMRHSection",

    components: {
        FormRadioGroup,
        FormSinistresMRHDialog,
        FormDefinitionValeurContenuDialog,
    },

    data() {
        return {
            typeResidenceRules: [
                (v) => !!v || "Le type de résidence est obligatoire",
            ],
            typeBienRules: [(v) => !!v || "Le type de bien est obligatoire"],
            typePersonneRules: [
                (v) => !!v || "Le type de personne est obligatoire",
            ],
            superficieRules: [
                (v) => !!v || "La superficie de votre bien est obligatoire",
                (v) =>
                    /^[1-9][0-9]{0,5}$/.test(v) ||
                    "La superficie de votre bien n'est pas valide",
            ],
            // valeurRules: [
            //     (v) => !!v || "La valeur de votre bien est obligatoire",
            //     (v) =>
            //         /^[1-9][0-9]{0,9}$/.test(v) ||
            //         "La valeur de votre bien n'est pas valide",
            // ],
            valeurContenuRules: [
                (v) => !!v || "La valeur de votre contenu est obligatoire",
                (v) =>
                    v >= 5000 ||
                    "La valeur de votre contenu doit être supérieure ou égale à 5000€",
                (v) =>
                    /^[1-9][0-9]{0,9}$/.test(v) ||
                    "La valeur de votre contenu n'est pas valide",
            ],
            nbPiecesRules: [
                (v) =>
                    !!v || "La nombre de pièces de votre bien est obligatoire",
                (v) =>
                    /^[1-9][0-9]{0,2}$/.test(v) ||
                    "Le nombre de pièces de votre bien n'est pas valide",
            ],
            superficieSupRules: [
                (v) =>
                    /(null|^$|[1-9][0-9]{0,5}$)/.test(v) ||
                    "La superficie de l'espace supplémentaire de votre bien n'est pas valide",
            ],
            distanceMerRules: [
                (v) => !!v || "La distance à la mer est obligatoire",
            ],
            superficieDependancesRules: [
                (v) =>
                    !!v ||
                    v === 0 ||
                    "La superficie des dépendances bien de votre bien est obligatoire",
                (v) =>
                    /^[0-9][0-9]{0,5}$/.test(v) ||
                    "La superficie des dépendances bien de votre bien n'est pas valide",
            ],
            installationsExterieuresRules: [
                (v) => !!v || "Une réponse est obligatoire",
            ],
            dommagesElectriquesRules: [
                (v) => !!v || "Une réponse est obligatoire",
            ],
            individuelleScolaireRules: [
                (v) => !!v || "Une réponse est obligatoire",
            ],
            piscineRules: [(v) => !!v || "Une réponse est obligatoire"],
            energiesRenouvelablesRules: [
                (v) => !!v || "Une réponse est obligatoire",
            ],
            garantieRules: [(v) => !!v || "Une garantie est obligatoire"],
            guaranteesDialog: false,
            definitionInstallationsExterieuresDialog: false,
            sinistresDialog: false,
            definitionValeurContenuDialog: false,
        };
    },

    computed: {
        ...mapGetters("form", ["data", "formData"]),
        typeResidence: {
            get() {
                return this.getModel("type_residence");
            },
        },
        typeBien: {
            get() {
                return this.getModel("type_bien");
            },
        },
        typePersonne: {
            get() {
                return this.getModel("type_occupation");
            },
        },
        superficie: {
            get() {
                if (isNaN(this.getModel("superficie"))) {
                    return null;
                } else {
                    return this.getModel("superficie");
                }
            },
            set(value) {
                this.setModel("superficie", parseInt(value));
            },
        },
        // valeur: {
        //     get() {
        //         if (isNaN(this.getModel("valeur"))) {
        //             return null;
        //         } else {
        //             return this.getModel("valeur");
        //         }
        //     },
        //     set(value) {
        //         this.setModel("valeur", parseInt(value));
        //     },
        // },
        valeurContenu: {
            get() {
                if (isNaN(this.getModel("valeur_contenu"))) {
                    return null;
                } else {
                    return this.getModel("valeur_contenu");
                }
            },
            set(value) {
                this.setModel("valeur_contenu", parseInt(value));
            },
        },
        nbPieces: {
            get() {
                if (isNaN(this.getModel("nb_pieces"))) {
                    return null;
                } else {
                    return this.getModel("nb_pieces");
                }
            },
            set(value) {
                this.setModel("nb_pieces", parseInt(value));
            },
        },
        superficieSup: {
            get() {
                if (isNaN(this.getModel("superficie_sup"))) {
                    return null;
                } else {
                    return this.getModel("superficie_sup");
                }
            },
            set(value) {
                this.setModel("superficie_sup", parseInt(value));
            },
        },
        distanceMer: {
            get() {
                return this.getModel("distance_mer");
            },
            set(value) {
                this.setModel("distance_mer", value);
            },
        },
        superficieDependances: {
            get() {
                if (isNaN(this.getModel("superficie_dependances"))) {
                    return null;
                } else {
                    return this.getModel("superficie_dependances");
                }
            },
            set(value) {
                this.setModel("superficie_dependances", parseInt(value));
            },
        },
        installationsExterieures: {
            get() {
                return this.getModel("installations_exterieures");
            },
        },
        dommagesElectriques: {
            get() {
                return this.getModel("dommages_electriques");
            },
        },
        individuelleScolaire: {
            get() {
                return this.getModel("individuelle_scolaire");
            },
        },
        piscine: {
            get() {
                return this.getModel("piscine");
            },
        },
        energiesRenouvelables: {
            get() {
                return this.getModel("energies_renouvelables");
            },
        },
        garantie: {
            get() {
                return this.formData.demande.garantie;
            },
            set(value) {
                this.setDemande({
                    field: "garantie",
                    value: value,
                });
            },
        },
    },

    methods: {
        ...mapActions("form", ["setDemande", "setDemandeMRH"]),
        getModel(field) {
            return this.formData.demande_mrh[field];
        },
        setModel(field, value) {
            this.setDemandeMRH({
                field: field,
                value: value,
            });
        },
        setTypeResidence(payload) {
            this.setModel("type_residence", payload);
        },
        setTypeBien(payload) {
            this.setModel("type_bien", payload);
        },
        setTypePersonne(payload) {
            this.setModel("type_occupation", payload);
        },
        setInstallationsExterieures(payload) {
            this.setModel("installations_exterieures", payload == "vrai");
        },
        setDommagesElectriques(payload) {
            this.setModel("dommages_electriques", payload == "vrai");
        },
        setIndividuelleScolaire(payload) {
            this.setModel("individuelle_scolaire", payload == "vrai");
        },
        setPiscine(payload) {
            this.setModel("piscine", payload == "vrai");
        },
        setEnergiesRenouvelables(payload) {
            this.setModel("energies_renouvelables", payload == "vrai");
        },
        setInformationDialog() {
            this.definitionInstallationsExterieuresDialog = true;
        },

        openSinistresMRHDialog() {
            this.sinistresDialog = true;
        },

        closeSinistresMRHDialog() {
            this.sinistresDialog = false;
        },

        openDefinitionValeurContenuDialog() {
            this.definitionValeurContenuDialog = true;
        },

        closeDefinitionValeurContenuDialog() {
            this.definitionValeurContenuDialog = false;
        },
    },
};
</script>

<style lang="scss">
button.max-width-100 {
    max-width: 100% !important;
}

button.max-width-100 > span.v-btn__content {
    width: 100%;
    white-space: normal;
}
</style>
