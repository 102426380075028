<template lang="pug">
DemandeMRHForm
</template>

<script>
import DemandeMRHForm from "@/components/demande/DemandeMRHForm";

export default {
  name: "DemandeMRH",
  components: {
    DemandeMRHForm,
  },
};
</script>
