<template lang="pug">
DemandeAutoForm
</template>

<script>
import DemandeAutoForm from "@/components/demande/DemandeAutoForm";

export default {
  name: "DemandeAuto",
  components: {
    DemandeAutoForm,
  },
};
</script>