import AuthService from "../services/auth.service";

const authData = JSON.parse(localStorage.getItem("auth-data"));

const initialState = authData
  ? { isAuthenticated: true, data: authData }
  : { isAuthenticated: false, data: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
  },
  actions: {
    async login({ commit }, credentials) {
      const authData = await AuthService.login(credentials);
      if (authData.success) {
        commit("loginSuccess", authData.data);
      } else {
        commit("loginFailure");
      }
      return authData;
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    async refreshToken({ commit }) {
      console.log("refreshToken");
      const authData = await AuthService.refreshToken();
      console.log(authData);
      if (authData.success) {
        commit("refreshTokenSuccess", authData.data);
      } else {
        commit("refreshTokenFailure");
      }
    },
  },
  mutations: {
    loginSuccess(state, authData) {
      state.isAuthenticated = true;
      state.data = authData;
    },
    loginFailure(state) {
      state.isAuthenticated = false;
      state.data = null;
    },
    refreshTokenSuccess(state, authData) {
      state.isAuthenticated = true;
      state.data = authData;
    },
    refreshTokenFailure(state) {
      state.isAuthenticated = false;
      state.data = null;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.data = null;
    },
  },
};
