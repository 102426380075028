import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import fr from "vuetify/lib/locale/fr";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);
const opts = {
  lang: { locales: { fr }, current: "fr" },
  icons: { iconfont: "md" },
  theme: {
    // dark: true,
    options: { customProperties: true, variations: false },
    themes: {
      light: {
        primary: "#003781",
        secondary: "#8cb2e1",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",

        tertiary: "#000", //#004d93
        tertiary_light: "#999", //#5999d4

        app_bar_bottom: "#000", //#ed6d30
        app_bar_top: "#fff",

        drawer_background: "#003781", //#ed6d30
        drawer_color: "#fff", //#fff

        button: "#003781", //#ed6d30
        button_light: "#999", //#fc9463
        button_color: "#fff", //#fff

        list_background: "#bdbdbd",
        list_background_light: "#e0e0e0",
        list_background_lighter: "#fafafa",
        list_color: "#fff",
      },
      dark: {
        primary: "#003781",
        tertiary: "#424242",
        tertiary_light: "#727272",
        app_bar_bottom: "#fff",
        drawer_color: "#fff",
        button: "#003781",
      },
    },
  },
};
export default new Vuetify(opts);
